import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import moment from "moment/moment";


require('./bootstrap');

Vue.config.productionTip = false;

moment.locale('ru');

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');