
























































import {Vue, Component} from "vue-property-decorator";
import {DefaultPaymentsCondition} from "@/models/entities/defaultPaymentsCondition/DefaultPaymentsCondition";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import LayoutService from "@/services/LayoutService";


@Component
export default class DefaultPaymentsConditionComponent extends Vue {
  protected defaultPaymentsCondition: DefaultPaymentsCondition | null = null;

  protected updatedSuccessful: boolean | null = null;
  protected errorMessage: string | null = null;

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.PaymentsCondition.getAsync().then((defaultPaymentsCondition: DefaultPaymentsCondition) => {
      this.defaultPaymentsCondition = defaultPaymentsCondition;
    })
  }

  async saveAsync() {
    if (this.defaultPaymentsCondition) {
      try {
        await Services.PaymentsCondition.editAsync(this.defaultPaymentsCondition);

        await this.loadAsync();

        this.setUpdateTimeout(true);
      } catch (error) {
        if (error instanceof ApplicationError)
          this.errorMessage = error.message;
        else
          this.errorMessage = 'Неизвестная ошибка.';
        this.setUpdateTimeout(false);
      }
    }
  }

  deleteItem(id: string) {
    if (this.defaultPaymentsCondition?.canRemove()) {
      this.defaultPaymentsCondition?.remove(id);
    }
  }

  addItem() {
    this.defaultPaymentsCondition?.addEmpty();
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}
