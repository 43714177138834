import {InventoryReportReelRecordStatus} from "@/models/entities/inventoryReport/inventoryReportReelRecordStatus";
import Warehouse, {WarehouseDto} from "@/models/entities/warehouse/warehouse";
import {InventoryReportReel, InventoryReportReelDto} from "@/models/entities/inventoryReport/inventoryReportReel";

export interface InventoryReportReelRecordDto {
    id: number;
    warehouse: WarehouseDto;
    reel: InventoryReportReelDto;
    status: InventoryReportReelRecordStatus;
}

export class InventoryReportReelRecord {
    public constructor(
        public readonly id: number | string,
        public readonly warehouse: Warehouse,
        public readonly reel: InventoryReportReel,
        public readonly status: InventoryReportReelRecordStatus
    ) {
    }

    public static fromObject(dto: InventoryReportReelRecordDto): InventoryReportReelRecord{
        return new InventoryReportReelRecord(
            dto.id,
            Warehouse.fromDto(dto.warehouse),
            InventoryReportReel.fromDto(dto.reel),
            dto.status);
    }
}