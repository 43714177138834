




























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import {Hint} from "@/models/entities/hint/hint";
import {HintSection} from "@/models/entities/hint/hintSection";
import {Roles} from "@/models/entities/hint/roles";

@Component
export default class HintComponent extends Vue{
  protected hints: Array<Hint> = [];
  protected hintSections: Array<HintSection> = [];
  protected roles: {[key: number]: Roles} = {
    1: Roles.Dealer,
    2: Roles.WarehouseOperator,
    3: Roles.FacilityOperator,
    4: Roles.Observer
  }

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Hint.listAsync().then((hints: Array<Hint>) => {
      this.hints = hints;
    });
    await Services.HintSection.listAsync().then((hintSections: Array<HintSection>) => {
      this.hintSections = hintSections;
    });
  }

  async saveAsync(hint: Hint) {
    if (hint.created)
      await this.createAsync(hint);
    else if (hint.updated)
      await this.editAsync(hint);
  }

  async deleteAsync(hint: Hint) {
    if (this.hints) {
      if (hint.created)
        this.hints = this.hints.filter(x => x != hint);
      else
        await this.removeAsync(hint);
    }
  }

  async createAsync(hint: Hint) {
    try {
      const createdHint = await Services.Hint.createAsync(hint);

      this.hints.splice(this.hints.indexOf(hint), 1, createdHint);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(hint: Hint) {
    try {
      await Services.Hint.editShortAsync(hint);

      this.setUpdateTimeout(true);

      hint.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(hint: Hint) {
    try {
      await Services.Hint.removeAsync(hint);

      this.hints = this.hints.filter(x => x != hint);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const hint = new Hint(
        v4(),
        '',
        this.hintSections[0],
        Roles.Dealer,
        '',
        []
    );

    hint.created = true;
    hint.updated = true;

    this.hints.push(hint);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

