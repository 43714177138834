import moment from "moment";
import {Employee, EmployeeDto} from "@/models/entities/common/employee";

export default class FacilityInspector extends Employee {
    public static entityName = 'FacilityInspector';

    constructor(
        id: number | string,
        name: string,
        email: string,
        createdAtUtc: moment.Moment
    ) {
        super(id, name, email, createdAtUtc)
    }

    public static fromObject(dto: EmployeeDto): FacilityInspector {
        return new FacilityInspector(
            dto.id,
            dto.name,
            dto.email,
            moment.utc(dto.createdAtUtc))
    }
}