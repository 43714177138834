import {MutationTree} from "vuex";
import {RootState} from "./types/RootState";
import {Profile} from "@/models/entities/profile/profile";
import {Brand} from "@/models/entities/product/cable/brand";
import Warehouse from "@/models/entities/warehouse/warehouse";

export const mutations: MutationTree<RootState> = {
    setProfile: function (state: RootState, profile: Profile){
        state.profile = profile;
    },
    clearProfile: function (state: RootState){
        state.profile = null;
    },
    updateBrands: function (state: RootState, brands: Array<Brand>){
        state.brands = brands;
    },
    updateWarehouses: function (state: RootState, warehouses: Array<Warehouse>){
        state.warehouses = warehouses;
    }
};