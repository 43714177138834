import {v4} from "uuid";
import ValidatorService from "@/services/ValidatorService";

export interface PaymentsConditionItemDto {
    days: number;
    rate: number;
}

export class PaymentsConditionItem {
    public created = false;

    public daysUpdated = false;
    public rateUpdated = false;

    public static maxDays = 365;

    constructor(
        public readonly id: string,
        private days: number,
        private rate: number) {
    }

    public static fromObject(data: PaymentsConditionItemDto): PaymentsConditionItem {
        return new PaymentsConditionItem(
            v4(),
            data.days,
            data.rate);
    }

    get updated(): boolean {
        return this.rateUpdated ||
            this.daysUpdated;
    }

    set updated(value: boolean) {
        this.daysUpdated = value;
        this.rateUpdated = value;
    }

    get valid(): boolean {
        return this.rateValid && this.daysValid;
    }

    get daysValid(): boolean {
        return ValidatorService.isNumberInRange(this.days, 0, PaymentsConditionItem.maxDays);
    }

    get rateValid(): boolean {
        return this.rate > 0 && this.rate <= 1;
    }

    get daysValue(): number {
        return this.days;
    }

    set daysValue(value: number) {
        this.days = value;
        this.daysUpdated = true;
    }

    get rateValue(): number {
        return this.rate;
    }

    set rateValue(value: number) {
        this.rate = value;
        this.rateUpdated = true;
    }

    get ratePercentValue(): number {
        return Math.round(this.rate * 100);
    }

    set ratePercentValue(value: number) {
        this.rate = value / 100;
        this.rateUpdated = true;
    }
}