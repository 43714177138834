import {PaymentsConditionItem, PaymentsConditionItemDto} from "@/models/entities/defaultPaymentsCondition/PaymentsConditionItem";
import moment from "moment";
import {v4} from "uuid";

export interface PaymentsConditionDto {
    updatedAtUtc: string | null;
    items: ReadonlyArray<PaymentsConditionItemDto>;
}

export class PaymentsCondition {
    public itemDeleted = false;

    constructor(
        public readonly updatedAtUtc: moment.Moment | null,
        public items: Array<PaymentsConditionItem>) {
    }

    public static fromObject(data: PaymentsConditionDto): PaymentsCondition {
        return new PaymentsCondition(
            data.updatedAtUtc ? moment.utc(data.updatedAtUtc) : null,
            data.items.map(x => PaymentsConditionItem.fromObject(x)));
    }

    public canRemove(): boolean {
        return this.items.length > 1;
    }

    public remove(id: string): void {
        this.items = this.items.filter(x => x.id !== id);
        this.itemDeleted = true;
    }

    public addEmpty(): void {
        const item = new PaymentsConditionItem(v4(), 0, 0);

        item.created = true;
        item.updated = true;

        this.items.push(item);
    }

    public sumOfRates(): number {
        return this.items.reduce((sum: number, item: PaymentsConditionItem) => Math.round((sum + item.rateValue) * 100) / 100, 0);
    }

    public sumOfRatesPercent(): number {
        return Math.round(this.sumOfRates() * 100);
    }

    get valid(): boolean {
        return this.ratesSumValid &&
            this.daysOrderValid &&
            this.itemsValid;
    }

    get ratesSumValid(): boolean {
        return this.sumOfRates() === 1;
    }

    get daysOrderValid(): boolean {
        for (let i = 0; i < this.items.length - 1; i++) {
            if (this.items[i].daysValue >= this.items[i + 1].daysValue)
                return false;
        }
        return true;
    }

    get itemsValid(): boolean {
        return this.items.every(x => x.valid)
    }

    get itemsUpdated(): boolean {
        return this.items.some(x => x.updated) ||
            this.itemDeleted;
    }

    get itemsCreated(): boolean {
        return this.items.some(x => x.created);
    }
}