import {ServiceBase} from "@/services/ServiceBase";
import {City, CityDto} from "@/models/entities/city/city";

export default class CityService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<City>> {
        return await this.postRequestWithResultAsync(
            `/api/City/List`,
            {},
            (data: CityDto[]) => data.map(x => City.fromObject(x)));
    }

    async createAsync(city: City): Promise<City> {
        return await this.postRequestWithResultAsync(
            `/api/City/Create`,
            {
                name: city.name
            },
            (data: CityDto) => City.fromObject(data));
    }

    async editAsync(city: City): Promise<void> {
        return await this.postRequestAsync(
            `/api/City/Edit`,
            {
                id: city.id,
                name: city.name
            });
    }

    async removeAsync(city: City): Promise<void> {
        return await this.postRequestAsync(
            `/api/City/Remove`,
            {
                id: city.id
            });
    }
}