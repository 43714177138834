export interface ShortPictureDto{
    id: number;
    fileName: string;
}

export class ShortPicture {
    constructor(
        public readonly id: number,
        public fileName: string
    ) {
    }

    public static fromDto(dto: ShortPictureDto): ShortPicture{
        return new ShortPicture(
            dto.id,
            dto.fileName
        )
    }
}