import {ServiceBase} from "@/services/ServiceBase";
import DefaultReelHoldingTimeSpan from "@/models/entities/reelHoldingTimeSpan/defaultReelHoldingTimeSpan";
import {ReelHoldingTimeSpanDto} from "@/models/entities/reelHoldingTimeSpan/reelHoldingTimeSpan";
import WarehouseBrandReelHoldingTimeSpan, {WarehouseBrandReelHoldingTimeSpanDto} from "@/models/entities/reelHoldingTimeSpan/warehouseBrandReelHoldingTimeSpan";

export default class ReelHoldingTimeSpanService extends ServiceBase{
    constructor() {
        super();
    }

    async getDefaultAsync(): Promise<DefaultReelHoldingTimeSpan> {
        return await this.postRequestWithResultAsync(
            `/api/ReelHoldingTimeSpan/GetDefault`,
            {},
            (data: ReelHoldingTimeSpanDto) => DefaultReelHoldingTimeSpan.fromDto(data));
    }

    async listAsync(): Promise<Array<WarehouseBrandReelHoldingTimeSpan>> {
        return  await this.postRequestWithResultAsync(
            `/api/ReelHoldingTimeSpan/List`,
            {},
            (data: WarehouseBrandReelHoldingTimeSpanDto[]) => Promise.all(data.map(async x => await WarehouseBrandReelHoldingTimeSpan.fromDto(x))));
    }

    async createAsync(timeSpan: WarehouseBrandReelHoldingTimeSpan): Promise<WarehouseBrandReelHoldingTimeSpan> {
        return await this.postRequestWithResultAsync(
            `/api/ReelHoldingTimeSpan/Create`,
            {
                brand: {
                    id: timeSpan.brand?.id
                },
                warehouse: {
                    id: timeSpan.warehouse?.id
                },
                days: timeSpan.days
            },
            (data: WarehouseBrandReelHoldingTimeSpanDto) => WarehouseBrandReelHoldingTimeSpan.fromDto(data));
    }

    async editAsync(timeSpan: WarehouseBrandReelHoldingTimeSpan): Promise<void> {
        return await this.postRequestAsync(
            `/api/ReelHoldingTimeSpan/Edit`,
            {
                id: timeSpan.id,
                brand: {
                    id: timeSpan.brand?.id
                },
                warehouse: {
                    id: timeSpan.warehouse?.id
                },
                days: timeSpan.days
            });
    }
    
    async editDefaultAsync(timeSpan: DefaultReelHoldingTimeSpan): Promise<void> {
        return await this.postRequestAsync(
            `/api/ReelHoldingTimeSpan/EditDefault`,
            {
                days: timeSpan.days
            });
    }

    async removeAsync(timeSpan: WarehouseBrandReelHoldingTimeSpan): Promise<void> {
        return await this.postRequestAsync(
            `/api/ReelHoldingTimeSpan/Remove`,
            {
                id: timeSpan.id
            });
    }
}