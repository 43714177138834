import {City, CityDto} from "@/models/entities/city/city";

export interface CityExtraChargeDto {
    id: number;
    city: CityDto;
    extraRate: number;
}

export class CityExtraCharge {
    public created = false;

    public cityUpdated = false;
    public extraRateUpdated = false;

    constructor(
        public readonly id: number | string,
        public _city: City,
        public _extraRate: number
    ) {
    }

    public static fromObject(dto: CityExtraChargeDto): CityExtraCharge {
        return new CityExtraCharge(
            dto.id,
            City.fromObject(dto.city),
            dto.extraRate
        )
    }

    get city(): City {
        return this._city;
    }

    set city(value: City) {
        this._city = value;
        this.cityUpdated = true;
    }

    get extraRate(): number {
        return this._extraRate;
    }

    set extraRate(value: number) {
        this._extraRate = value;
        this.extraRateUpdated = true;
    }

    get extraRateInPercent(): number {
        return Math.round(this.extraRate * 100);
    }

    set extraRateInPercent(value: number) {
        this.extraRate = value / 100;
    }

    get updated(): boolean {
        return this.cityUpdated ||
            this.extraRateUpdated;
    }

    set updated(value: boolean) {
        this.cityUpdated = value;
        this.extraRateUpdated = value;
    }

    get valid(): boolean {
        return this.extraRateValid;
    }

    get cityValid(): boolean {
        return this._city != null;
    }

    get extraRateValid(): boolean {
        return this.extraRate > 0 &&
            this.extraRate <= 1;
    }
}