import {ErrorDataViewModel, Error} from './Error';

export interface ApiResponseViewModel {
    success: boolean;
    error: ErrorDataViewModel | null;
}

export class ApiResponse {
    constructor(
        public readonly success: boolean,
        public readonly error: Error | null
    ) {
    }

    public static fromObject(data: ApiResponseViewModel): ApiResponse {
        return new ApiResponse(
            data.success,
            data.error ? Error.fromObject(data.error) : null
        );
    }
}

export interface TypedApiResponseViewModel<TData> extends ApiResponseViewModel {
    data: TData | null;
}

export class TypedApiResponse<TData> extends ApiResponse {
    constructor(
        success: boolean,
        error: Error | null,
        public readonly data: TData | null) {
        super(success, error);
    }

    public static fromObjectTyped<TInputData, TOutputData>(
        data: TypedApiResponseViewModel<TInputData>,
        map: (input: TInputData) => TOutputData): TypedApiResponse<TOutputData> {
        return new TypedApiResponse(
            data.success,
            data.error ? Error.fromObject(data.error) : null,
            data.data !== null && data.data !== undefined
                ? map(data.data)
                : null
        );
    }
}
