import ReelHoldingTimeSpan, {ReelHoldingTimeSpanDto} from "@/models/entities/reelHoldingTimeSpan/reelHoldingTimeSpan";

export default class DefaultReelHoldingTimeSpan extends ReelHoldingTimeSpan{
    constructor(
        id: number,
        days: number
    ) {
        super(id, days);
    }

    public static fromDto(dto: ReelHoldingTimeSpanDto): DefaultReelHoldingTimeSpan {
        return new DefaultReelHoldingTimeSpan(
            dto.id,
            dto.days
        )
    }
}