import moment from "moment";
import ValidatorService from "@/services/ValidatorService";

export interface EmployeeDto {
    id: number;
    name: string;
    email: string;
    createdAtUtc: string;
}

export abstract class Employee {
    public static entityName = 'Employee';

    public created = false;

    public nameUpdated = false;
    public emailUpdated = false;

    protected constructor(
        public readonly id: number | string,
        public _name: string,
        public _email: string,
        public readonly createdAtUtc: moment.Moment
    ) {
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
        this.nameUpdated = true;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
        this.emailUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.emailUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.emailUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid &&
            this.emailValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isNotEmpty(this.name);
    }

    get emailValid(): boolean {
        return ValidatorService.isEmailValid(this.email);
    }
}