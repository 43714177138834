import {ManufacturerType} from "@/models/entities/manufacturer/manufacturerType";

export interface ManufacturerDto{
    id: number;
    name: string;
    type: ManufacturerType;
}

export default class Manufacturer{
    constructor(
        public id: number,
        public name: string,
        public type: ManufacturerType
    ) {
    }

    public static fromDto(dto: ManufacturerDto): Manufacturer{
        return new Manufacturer(
            dto.id,
            dto.name,
            dto.type
        )
    }
}