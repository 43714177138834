

































































































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import {ShortPrice} from "@/models/entities/price/shortPrice";
import ApplicationError from "@/models/errors/ApplicationError";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import {ShortManufacturerPrice} from "@/models/entities/price/shortManufacturerPrice";
import Manufacturer, {ManufacturerDto} from "@/models/entities/manufacturer/manufacturer";
import moment from "moment";

@Component
export default class ManufacturerPriceComponent extends Vue{
  protected price: ShortPrice | null = null;
  protected manufacturers: Array<Manufacturer> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    let id = parseInt(this.$route.params.id);
    await Services.Price.getAsync(id).then((price: ShortPrice) => {
      this.price = price;
    });
    await Services.Manufacturer.listAsync().then((manufacturers: ManufacturerDto[]) => {
      this.manufacturers = manufacturers
    });
  }

  async saveAsync(manufacturerPrice: ShortManufacturerPrice) {
    if (manufacturerPrice.created)
      await this.createAsync(manufacturerPrice);
    else if (manufacturerPrice.updated)
      await this.editAsync(manufacturerPrice);
  }

  async deleteAsync(manufacturerPrice: ShortManufacturerPrice) {
    if (this.price) {
      if (manufacturerPrice.created)
        this.price.manufacturerPrices = this.price.manufacturerPrices.filter(x => x != manufacturerPrice);
      else
        await this.removeAsync(manufacturerPrice);
    }
  }

  async createAsync(manufacturerPrice: ShortManufacturerPrice) {
    if (this.price){
      try {
        let id = parseInt(this.price.id.toString());

        const createdPrice = await Services.ManufacturerPrice.createAsync(manufacturerPrice, id);

        this.price.manufacturerPrices.splice(this.price.manufacturerPrices.indexOf(manufacturerPrice), 1, createdPrice);

        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  async editAsync(manufacturerPrice: ShortManufacturerPrice) {
    try {
      await Services.ManufacturerPrice.editAsync(manufacturerPrice);

      this.setUpdateTimeout(true);

      manufacturerPrice.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message);
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(manufacturerPrice: ShortManufacturerPrice) {
    if (this.price){
      try {
        await Services.ManufacturerPrice.removeAsync(manufacturerPrice);

        this.price.manufacturerPrices = this.price.manufacturerPrices.filter(x => x != manufacturerPrice);

        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  async uploadPriceAsync(event: Event): Promise<void> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        try{
          await Services.File.importPriceAsync(file);
          await this.loadAsync();
          this.setUpdateTimeout(true);
        } catch (error) {
          this.errorMessages.splice(0, this.errorMessages.length);
          if (error instanceof ApplicationError)
            this.errorMessages.push(error.message)
          else
            this.errorMessages.push('Неизвестная ошибка');
          this.setUpdateTimeout(false);
        }
      }
    }
  }

  async saveDefaultAsync() {
    if (this.price?.defaultPaymentsCondition) {
      try {
        await Services.PaymentsCondition.editPriceDefaultConditionsAsync(this.price.id, this.price.defaultPaymentsCondition);

        await this.loadAsync();

        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message);
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  deleteDefaultItem(id: string) {
    if (this.price?.defaultPaymentsCondition?.canRemove()) {
      this.price?.defaultPaymentsCondition?.remove(id);
    }
  }

  addDefaultItem() {
    this.price?.defaultPaymentsCondition?.addEmpty();
  }

  addEmpty() {
    const manufacturerPrice = new ShortManufacturerPrice(
        v4(),
        moment.utc(),
        this.manufacturers[0],
        0,
        0
    );

    manufacturerPrice.created = true;
    manufacturerPrice.updated = true;

    this.price?.manufacturerPrices.push(manufacturerPrice);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

