import {ServiceBase} from "@/services/ServiceBase";
import {DefaultPaymentsCondition} from "@/models/entities/defaultPaymentsCondition/DefaultPaymentsCondition";
import {PaymentsCondition, PaymentsConditionDto} from "@/models/entities/defaultPaymentsCondition/PaymentsCondition";

export default class PaymentsConditionService extends ServiceBase
{
    constructor() {
        super();
    }

    async getAsync(): Promise<DefaultPaymentsCondition> {
        return await this.postRequestWithResultAsync(
            '/api/DefaultPaymentsCondition/Get',
            {
            },
            (data: PaymentsConditionDto) => DefaultPaymentsCondition.fromObject(data));
    }

    async editAsync(defaultPaymentsCondition: DefaultPaymentsCondition): Promise<void> {
        return await this.postRequestAsync(
            '/api/DefaultPaymentsCondition/Edit',
            {
                items: defaultPaymentsCondition.items
            });
    }

    async editPricePrepaymentConditionsAsync(priceId: number | string, paymentsCondition: PaymentsCondition): Promise<void> {
        return await this.postRequestAsync(
            '/api/PaymentsCondition/EditPricePrepayment',
            {
                priceId: priceId,
                items: paymentsCondition.items
            });
    }

    async editPriceDefaultConditionsAsync(priceId: number | string, paymentsCondition: PaymentsCondition): Promise<void> {
        return await this.postRequestAsync(
            '/api/PaymentsCondition/EditPriceDefault',
            {
                priceId: priceId,
                items: paymentsCondition.items
            });
    }
}