











































































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import {ShortPrice} from "@/models/entities/price/shortPrice";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import {PaymentsCondition} from "@/models/entities/defaultPaymentsCondition/PaymentsCondition";

@Component
export default class PriceComponent extends Vue{
  protected prices: Array<ShortPrice> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Price.listAsync().then((prices: Array<ShortPrice>) => {
      this.prices = prices;
    });
  }

  async saveAsync(price: ShortPrice) {
    if (price.created)
      await this.createAsync(price);
    else if (price.updated)
      await this.editAsync(price);
  }

  async deleteAsync(price: ShortPrice) {
    if (this.prices) {
      if (price.created)
        this.prices = this.prices.filter(x => x != price);
      else
        await this.removeAsync(price);
    }
  }

  async createAsync(price: ShortPrice) {
    try {
      const createdPrice = await Services.Price.createAsync(price);

      this.prices.splice(this.prices.indexOf(price), 1, createdPrice);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(price: ShortPrice) {
    try {
      await Services.Price.editAsync(price);

      this.setUpdateTimeout(true);

      price.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(price: ShortPrice) {
    try {
      await Services.Price.removeAsync(price);

      this.prices = this.prices.filter(x => x != price);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty(isStandard: boolean) {
    const price = new ShortPrice(
        v4(),
        '',
        isStandard,
        [],
        new PaymentsCondition(moment(), []),
    );

    price.created = true;
    price.updated = true;

    this.prices.push(price);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

