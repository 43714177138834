import {ServiceBase} from "@/services/ServiceBase";
import {InventoryReport, InventoryReportDto} from "@/models/entities/inventoryReport/inventoryReport";

export default class InventoryReportService extends ServiceBase{
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<InventoryReport>> {
        return await this.postRequestWithResultAsync(
            `/api/Inventory/List`,
            {},
            (dtos: InventoryReportDto[]) => dtos.map(x => InventoryReport.fromObject(x)));
    }

    async updateAsync(): Promise<InventoryReport> {
        return await this.postRequestWithResultAsync(
            `/api/Inventory/Update`,
            {},
            (dto: InventoryReportDto) => InventoryReport.fromObject(dto));
    }
}