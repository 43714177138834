import moment from "moment";
import {InventoryReportReelRecord, InventoryReportReelRecordDto} from "@/models/entities/inventoryReport/inventoryReportReelRecord";

export interface InventoryReportDto {
    id: number;
    createdAtUtc: string;
    records: ReadonlyArray<InventoryReportReelRecordDto>;
}

export class InventoryReport {
    public constructor(
        public readonly id: number | string,
        public readonly createdAtUtc: moment.Moment,
        public records: Array<InventoryReportReelRecord>
    ) {
    }

    public static fromObject(dto: InventoryReportDto): InventoryReport{
        return new InventoryReport(
            dto.id,
            moment.utc(dto.createdAtUtc),
            dto.records.map(x => InventoryReportReelRecord.fromObject(x))
        )
    }
}