

























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import {Shell} from "@/models/entities/product/cable/shell";

@Component
export default class ShellComponent extends Vue{
  protected shells: Array<Shell> = [];

  protected updatedSuccessful: boolean | null = null;
  protected needUpdate = false;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Shell.listAsync().then((shells: Array<Shell>) => {
      this.shells = shells;
    });
  }

  async syncAsync() {
    await Services.Shell.syncAsync();
    this.needUpdate = false;
  }

  async saveAsync(shell: Shell) {
    if (shell.created)
      await this.createAsync(shell);
    else if (shell.updated) {
      await this.editAsync(shell);
      this.needUpdate = true;
    }
  }

  async deleteAsync(shell: Shell) {
    if (this.shells) {
      if (shell.created)
        this.shells = this.shells.filter(x => x != shell);
      else
        await this.removeAsync(shell);
    }
  }

  async createAsync(shell: Shell) {
    try {
      const createdShell = await Services.Shell.createAsync(shell);

      this.shells.splice(this.shells.indexOf(shell), 1, createdShell);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(shell: Shell) {
    try {
      await Services.Shell.editAsync(shell);

      this.setUpdateTimeout(true);

      shell.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(shell: Shell) {
    try {
      await Services.Shell.removeAsync(shell);

      this.shells = this.shells.filter(x => x != shell);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const shell = new Shell(
        v4(),
        '',
        '',
        moment.utc()
    );

    shell.created = true;
    shell.updated = true;

    this.shells.push(shell);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

