import {ServiceBase} from "@/services/ServiceBase";
import {Picture, PictureDto} from "@/models/entities/picture/picture";

export default class PictureService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Picture>> {
        return await this.postRequestWithResultAsync(
            `/api/Picture/List`,
            {},
            (data: PictureDto[]) => data.map(x => Picture.fromDto(x)));
    }

    async removeAsync(picture: Picture): Promise<void> {
        return await this.postRequestAsync(
            `/api/Picture/Remove`,
            {
                id: picture.id
            });
    }
}