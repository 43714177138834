export interface ProfileDto{
    login: string;
}

export class Profile{
    constructor(
        public readonly login: string
    ) {
    }
    
    public static fromDto(dto: ProfileDto): Profile{
        return new Profile(
            dto.login
        )
    }
}