



















































































































import {Vue, Component} from "vue-property-decorator";
import {OurFirm} from "@/models/entities/ourFirm/ourFirm";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import LayoutService from "@/services/LayoutService";

@Component
export default class OurFirmComponent extends Vue {
  protected ourFirm: OurFirm | null = null;

  protected updatedSuccessful: boolean | null = null;
  protected errorMessage: string | null = null;

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.OurFirm.getAsync().then((ourFirm: OurFirm) => {
      this.ourFirm = ourFirm;
    });
  }

  async saveAsync() {
    if (this.ourFirm) {
      try {
        await Services.OurFirm.editAsync(this.ourFirm);

        await this.loadAsync();

        this.setUpdateTimeout(true);
      } catch (error) {
        if (error instanceof ApplicationError)
          this.errorMessage = error.message;
        else
          this.errorMessage = 'Неизвестная ошибка.';
        this.setUpdateTimeout(false);
      }
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }
  
  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}
