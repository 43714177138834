import ValidatorService from "@/services/ValidatorService";

export interface HintLinkDto {
    id: number;
    url: string;
}

export class HintLink{
    public created = false;

    public urlUpdated = false;

    constructor(
        public readonly id: number | string,
        public _url: string
    ) {
    }

    public static fromDto(dto: HintLinkDto): HintLink{
        return new HintLink(
            dto.id,
            dto.url
        )
    }

    get url(): string{
        return this._url;
    }

    set url(value: string){
        this._url = value;
        this.urlUpdated = true;
    }

    get updated(): boolean {
        return this.urlUpdated;
    }

    set updated(value: boolean) {
        this.urlUpdated = value;
    }

    get valid(): boolean {
        return this.urlValid;
    }

    get urlValid(): boolean {
        return ValidatorService.isLengthInRange(this.url, 1, 64);
    }
}