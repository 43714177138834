




















































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import LayoutService from "@/services/LayoutService";
import {Hint} from "@/models/entities/hint/hint";
import {HintLink} from "@/models/entities/hint/hintLink";
import {v4} from "uuid";

@Component
export default class HintHtmlComponent extends Vue{
  protected hint: Hint | null = null;

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    let id = parseInt(this.$route.params.id);
    await Services.Hint.getAsync(id).then((hint: Hint) => {
      this.hint = hint;
    });
  }

  async saveAsync(hint: Hint) {
    if (hint.updated)
      await this.editAsync(hint);
  }

  async editAsync(hint: Hint) {
    try {
      await Services.Hint.editAsync(hint);

      this.setUpdateTimeout(true);

      hint.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message);
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addLink() {
    const hintLink = new HintLink(
        v4(),
        ''
    );

    hintLink.created = true;
    hintLink.updated = true;

    if (this.hint?.links === null){
      this.hint.links = [];
    }

    this.hint?.links?.push(hintLink);
  }

  async saveLinkAsync(hintLink: HintLink) {
    if (hintLink.created)
      await this.createLinkAsync(hintLink);
    else if (hintLink.updated)
      await this.editLinkAsync(hintLink);
  }

  async createLinkAsync(hintLink: HintLink) {
    if (this.hint){
      try {
        const createdHintLink = await Services.HintLink.createAsync(this.hint, hintLink);

        if (!this.hint.links){
          this.hint.links = [];
        }

        this.hint.links.splice(this.hint.links.indexOf(hintLink), 1, createdHintLink);

        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  async editLinkAsync(hintLink: HintLink) {
    if (this.hint && this.hint.links) {
      try {
        await Services.HintLink.editAsync(hintLink);

        this.setUpdateTimeout(true);

        hintLink.updated = false;
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  async deleteLinkAsync(hintLink: HintLink){
    if (this.hint && this.hint.links) {
      if (hintLink.created)
        this.hint.links = this.hint.links.filter(x => x != hintLink);
      else
        await this.removeLinkAsync(hintLink);
    }
  }

  async removeLinkAsync(hintLink: HintLink) {
    if (this.hint && this.hint.links){
      try {
        await Services.HintLink.removeAsync(hintLink);

        this.hint.links = this.hint.links.filter(x => x != hintLink);
  
        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

