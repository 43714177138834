








































































































































































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import {Category} from "@/models/entities/product/cable/category";
import {ShortPicture} from "@/models/entities/picture/shortPicture";
import {Brand} from "@/models/entities/product/cable/brand";
import LayoutService from "@/services/LayoutService";
import {Shell} from "@/models/entities/product/cable/shell";
import {ExclusiveCategory} from "@/models/entities/product/cable/exclusiveCategory";

@Component
export default class BrandComponent extends Vue {
  protected brands: Array<Brand> = [];
  protected categories: Array<Category> = [];
  protected shells: Array<Shell> = [];
  protected exclusiveCategories: Array<ExclusiveCategory> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Brand.listAsync().then((brands: Array<Brand>) => {
      this.brands = brands.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
    });
    await Services.Category.listAsync().then((categories: Array<Category>) => {
      this.categories = categories.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
    });
    await Services.Shell.listAsync().then((shells: Array<Shell>) => {
      this.shells = shells.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
    });
    await Services.ExclusiveCategory.listAsync().then((exclusiveCategories: Array<ExclusiveCategory>) => {
      this.exclusiveCategories = exclusiveCategories;
    });
  }

  async setExclusiveCategoryLogoAsync(event: Event, exclusiveCategory: ExclusiveCategory): Promise<void> {
    exclusiveCategory.logo = await this.uploadPictureAsync(event);
  }

  async setExclusiveCategoryPageImageAsync(event: Event, exclusiveCategory: ExclusiveCategory): Promise<void> {
    exclusiveCategory.pageImage = await this.uploadPictureAsync(event);
  }

  async setLogoAsync(event: Event, brand: Brand): Promise<void> {
    brand.logo = await this.uploadPictureAsync(event);
  }

  async setPageImageAsync(event: Event, brand: Brand): Promise<void> {
    brand.pageImage = await this.uploadPictureAsync(event);
  }

  async uploadPictureAsync(event: Event): Promise<ShortPicture> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        return await Services.File.createPictureAsync(file);
      }
    }
    throw new Error();
  }

  async saveAsync(brand: Brand) {
    if (brand.created)
      await this.createAsync(brand);
    else if (brand.updated)
      await this.editAsync(brand);
  }

  async deleteAsync(brand: Brand) {
    if (this.brands) {
      if (brand.created)
        this.brands = this.brands.filter(x => x != brand);
      else
        await this.removeAsync(brand);
    }
  }

  async createAsync(brand: Brand) {
    try {
      const createdBrand = await Services.Brand.createAsync(brand);

      this.brands.splice(this.brands.indexOf(brand), 1, createdBrand);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(brand: Brand) {
    try {
      await Services.Brand.editAsync(brand);

      this.setUpdateTimeout(true);

      brand.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(brand: Brand) {
    try {
      await Services.Brand.removeAsync(brand);

      this.brands = this.brands.filter(x => x != brand);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const brand = new Brand(
        v4(),
        'Новая марка',
        'New brand',
        '',
        '',
        0,
        0,
        moment.utc(),
        this.categories[0],
        null,
        null
    );

    brand.created = true;
    brand.updated = true;

    this.brands.unshift(brand);
  }

  async saveExclusiveCategoryAsync(exclusiveCategory: ExclusiveCategory) {
    if (exclusiveCategory.created)
      await this.createExclusiveCategoryAsync(exclusiveCategory);
    else if (exclusiveCategory.updated)
      await this.editExclusiveCategoryAsync(exclusiveCategory);
  }

  async deleteExclusiveCategoryAsync(exclusiveCategory: ExclusiveCategory) {
    if (this.exclusiveCategories) {
      if (exclusiveCategory.created)
        this.exclusiveCategories = this.exclusiveCategories.filter(x => x != exclusiveCategory);
      else
        await this.removeExclusiveCategoryAsync(exclusiveCategory);
    }
  }

  async createExclusiveCategoryAsync(exclusiveCategory: ExclusiveCategory) {
    try {
      const createdExclusiveCategory = await Services.ExclusiveCategory.createAsync(exclusiveCategory);

      this.exclusiveCategories.splice(this.exclusiveCategories.indexOf(exclusiveCategory), 1, createdExclusiveCategory);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editExclusiveCategoryAsync(exclusiveCategory: ExclusiveCategory) {
    try {
      await Services.ExclusiveCategory.editAsync(exclusiveCategory);

      this.setUpdateTimeout(true);

      exclusiveCategory.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeExclusiveCategoryAsync(exclusiveCategory: ExclusiveCategory) {
    try {
      await Services.ExclusiveCategory.removeAsync(exclusiveCategory);

      this.exclusiveCategories = this.exclusiveCategories.filter(x => x != exclusiveCategory);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmptyExclusiveCategory() {
    const exclusiveCategory = new ExclusiveCategory(
        v4(),
        '',
        '',
        this.categories[0],
        this.brands[0],
        null,
        null,
        moment.utc(),
        this.shells.slice(0, 1)
    );

    exclusiveCategory.created = true;
    exclusiveCategory.updated = true;

    this.exclusiveCategories.unshift(exclusiveCategory);
  }

  checkShell(exclusiveCategory: ExclusiveCategory, shell: Shell){
    let exclusiveCategoryShell = exclusiveCategory.shells.find(x => x.id === shell.id);
    if (exclusiveCategoryShell === undefined){
      exclusiveCategory.shells.push(shell);
      exclusiveCategory.shellsUpdated = true;
    } else {
      exclusiveCategory.shells = exclusiveCategory.shells.filter(x => x.id != shell.id);
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 10000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }

  getPictureUrl(id: number): string{
    return `/api/Picture/Get/${id}`;
  }
}

