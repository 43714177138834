import {ServiceBase} from "@/services/ServiceBase";
import {Hint, HintDto} from "@/models/entities/hint/hint";

export default class HintService extends ServiceBase {
    constructor() {
        super();
    }

    async getAsync(id: number): Promise<Hint> {
        return await this.postRequestWithResultAsync(
            `/api/Hint/Get`,
            {
                id: id
            },
            (data: HintDto) => Hint.fromDto(data));
    }
    
    async listAsync(): Promise<Array<Hint>> {
        return await this.postRequestWithResultAsync(
            `/api/Hint/List`,
            {},
            (data: HintDto[]) => data.map(x => Hint.fromDto(x)));
    }

    async createAsync(hint: Hint): Promise<Hint> {
        return await this.postRequestWithResultAsync(
            `/api/Hint/Create`,
            {
                name: hint.name,
                hintSectionId: hint.hintSection.id,
                html: hint.html,
                urls: hint.links,
                role: hint.role
            },
            (data: HintDto) => Hint.fromDto(data));
    }

    async editAsync(hint: Hint): Promise<void> {
        return await this.postRequestAsync(
            `/api/Hint/Edit`,
            {
                id: hint.id,
                name: hint.name,
                hintSectionId: hint.hintSection.id,
                html: hint.html,
                urls: hint.links?.map(x => ({
                    id: typeof x.id === "string" ? null : x.id,
                    url: x.url
                })),
                role: hint.role
            });
    }

    async editShortAsync(hint: Hint): Promise<void> {
        return await this.postRequestAsync(
            `/api/Hint/EditShort`,
            {
                id: hint.id,
                name: hint.name,
                hintSectionId: hint.hintSection.id,
                urls: hint.links?.map(x => ({
                    id: typeof x.id === "string" ? null : x.id,
                    url: x.url
                })),
                role: hint.role
            });
    }

    async removeAsync(hint: Hint): Promise<void> {
        return await this.postRequestAsync(
            `/api/Hint/Remove`,
            {
                id: hint.id
            });
    }
}