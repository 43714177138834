import {BankDetails, BankDetailsDto} from "@/models/entities/ourFirm/bankDetails";
import ValidatorService from "@/services/ValidatorService";

export interface OurFirmDto {
    email: string;
    documentationEmail: string;
    name: string;
    inn: string;
    kpp: string;
    legalAddress: string;
    postalAddress: string;
    bankDetails: BankDetailsDto;
    phone: string;
    webSite: string;
    directorName: string;
    accountantName: string;
    responsibleName: string;
}

export class OurFirm {
    public nameUpdated = false;
    public emailUpdated = false;
    public documentationEmailUpdated = false;
    public innUpdated = false;
    public kppUpdated = false;
    public legalAddressUpdated = false;
    public postalAddressUpdated = false;
    public bikUpdated = false;
    public bankNameUpdated = false;
    public correspondentAccountUpdated = false;
    public transactionalAccountUpdated = false;
    public phoneUpdated = false;
    public webSiteUpdated = false;
    public directorNameUpdated = false;
    public accountantNameUpdated = false;
    public responsibleNameUpdated = false;
    
    constructor(
        private _email: string,
        private _documentationEmail: string,
        private _name: string,
        private _inn: string,
        private _kpp: string,
        private _legalAddress: string,
        private _postalAddress: string,
        public readonly bankDetails: BankDetails,
        private _phone: string,
        private _webSite: string,
        private _directorName: string,
        private _accountantName: string,
        private _responsibleName: string
    ) {
    }

    public static FromObject(ourFirm: OurFirmDto): OurFirm {
        return new OurFirm(
            ourFirm.email,
            ourFirm.documentationEmail,
            ourFirm.name,
            ourFirm.inn,
            ourFirm.kpp,
            ourFirm.legalAddress,
            ourFirm.postalAddress,
            BankDetails.FromObject(ourFirm.bankDetails),
            ourFirm.phone,
            ourFirm.webSite,
            ourFirm.directorName,
            ourFirm.accountantName,
            ourFirm.responsibleName
        )
    }
    
    get email(): string{
        return this._email;
    }
    
    set email(value: string){
        this._email = value;
        this.emailUpdated = true;
    }
    
    get documentationEmail(): string{
        return this._documentationEmail;
    }
    
    set documentationEmail(value: string){
        this._documentationEmail = value;
        this.documentationEmailUpdated = true;
    }

    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }
    
    get inn(): string{
        return this._inn;
    }
    
    set inn(value: string){
        this._inn = value;
        this.innUpdated = true;
    }

    get kpp(): string{
        return this._kpp;
    }

    set kpp(value: string){
        this._kpp = value;
        this.kppUpdated = true;
    }

    get legalAddress(): string{
        return this._legalAddress;
    }

    set legalAddress(value: string){
        this._legalAddress = value;
        this.legalAddressUpdated = true;
    }

    get postalAddress(): string{
        return this._postalAddress;
    }

    set postalAddress(value: string){
        this._postalAddress = value;
        this.postalAddressUpdated = true;
    }

    get bik(): string{
        return this.bankDetails.bik;
    }

    set bik(value: string){
        this.bankDetails.bik = value;
        this.bikUpdated = true;
    }

    get bankName(): string{
        return this.bankDetails.bankName;
    }

    set bankName(value: string){
        this.bankDetails.bankName = value;
        this.bankNameUpdated = true;
    }

    get correspondentAccount(): string{
        return this.bankDetails.correspondentAccount;
    }

    set correspondentAccount(value: string){
        this.bankDetails.correspondentAccount = value;
        this.correspondentAccountUpdated = true;
    }

    get transactionalAccount(): string{
        return this.bankDetails.transactionalAccount;
    }

    set transactionalAccount(value: string){
        this.bankDetails.transactionalAccount = value;
        this.transactionalAccountUpdated = true;
    }

    get phone(): string{
        return this._phone;
    }

    set phone(value: string){
        this._phone = value;
        this.phoneUpdated = true;
    }

    get webSite(): string{
        return this._webSite;
    }

    set webSite(value: string){
        this._webSite = value;
        this.webSiteUpdated = true;
    }

    get directorName(): string{
        return this._directorName;
    }

    set directorName(value: string){
        this._directorName = value;
        this.directorNameUpdated = true;
    }

    get accountantName(): string{
        return this._accountantName;
    }

    set accountantName(value: string){
        this._accountantName = value;
        this.accountantNameUpdated = true;
    }

    get responsibleName(): string{
        return this._responsibleName;
    }

    set responsibleName(value: string){
        this._responsibleName = value;
        this.responsibleNameUpdated = true;
    }
    
    get updated(): boolean{
        return this.nameUpdated ||
        this.emailUpdated ||
        this.documentationEmailUpdated ||
        this.innUpdated ||
        this.kppUpdated ||
        this.legalAddressUpdated ||
        this.postalAddressUpdated ||
        this.bikUpdated ||
        this.bankNameUpdated ||
        this.correspondentAccountUpdated ||
        this.transactionalAccountUpdated ||
        this.phoneUpdated ||
        this.webSiteUpdated ||
        this.directorNameUpdated ||
        this.accountantNameUpdated ||
        this.responsibleNameUpdated;
    }
    
    set updated(value: boolean){
        this.nameUpdated = value;
        this.emailUpdated = value;
        this.documentationEmailUpdated = value;
        this.innUpdated = value;
        this.kppUpdated = value;
        this.legalAddressUpdated = value;
        this.postalAddressUpdated = value;
        this.bikUpdated = value;
        this.bankNameUpdated = value;
        this.correspondentAccountUpdated = value;
        this.transactionalAccountUpdated = value;
        this.phoneUpdated = value;
        this.webSiteUpdated = value;
        this.directorNameUpdated = value;
        this.accountantNameUpdated = value;
        this.responsibleNameUpdated = value;
    }
    
    get valid(): boolean {
        return this.nameValid &&
            this.emailValid &&
            this.documentationEmailValid &&
            this.innValid &&
            this.kppValid &&
            this.legalAddressValid &&
            this.postalAddressValid &&
            this.bikValid &&
            this.bankNameValid &&
            this.correspondentAccountValid &&
            this.transactionalAccountValid &&
            this.phoneValid &&
            this.webSiteValid &&
            this.directorNameValid &&
            this.accountantNameValid &&
            this.responsibleNameValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 64);
    }

    get emailValid(): boolean {
        return ValidatorService.isLengthInRange(this.email, 1, 64) &&
            ValidatorService.isEmailValid(this.email);
    }

    get documentationEmailValid(): boolean {
        return ValidatorService.isLengthInRange(this.documentationEmail, 1, 64) &&
            ValidatorService.isEmailValid(this.documentationEmail);
    }

    get innValid(): boolean {
        const expression = /^[0-9]{10,12}$/;
        return (this.inn.length === 10 ||
            this.inn.length === 12) &&
            expression.test(this.inn);
    }

    get kppValid(): boolean {
        const expression = /^[0-9]{9}$/;
        return expression.test(this.kpp);
    }

    get legalAddressValid(): boolean {
        return ValidatorService.isNotEmpty(this.legalAddress);
    }

    get postalAddressValid(): boolean {
        return ValidatorService.isNotEmpty(this.postalAddress);
    }

    get bikValid(): boolean {
        const expression = /^[0-9]{9}$/;
        return expression.test(this.bankDetails.bik);
    }

    get bankNameValid(): boolean {
        return ValidatorService.isNotEmpty(this.bankDetails.bankName);
    }

    get correspondentAccountValid(): boolean {
        const expression = /^[0-9]{20}$/;
        return expression.test(this.bankDetails.correspondentAccount);
    }

    get transactionalAccountValid(): boolean {
        const expression = /^[0-9]{20}$/;
        return expression.test(this.bankDetails.transactionalAccount);
    }

    get phoneValid(): boolean {
        return ValidatorService.isLengthInRange(this.phone, 1, 32);
    }

    get webSiteValid(): boolean {
        return ValidatorService.isLengthInRange(this.webSite, 1, 32);
    }

    get directorNameValid(): boolean {
        return ValidatorService.isNotEmpty(this.directorName);
    }

    get accountantNameValid(): boolean {
        return ValidatorService.isNotEmpty(this.accountantName);
    }

    get responsibleNameValid(): boolean {
        return ValidatorService.isNotEmpty(this.responsibleName);
    }
}