import { render, staticRenderFns } from "./FacilityManagerComponent.vue?vue&type=template&id=940f94f6&scoped=true&"
import script from "./FacilityManagerComponent.vue?vue&type=script&lang=ts&"
export * from "./FacilityManagerComponent.vue?vue&type=script&lang=ts&"
import style0 from "./FacilityManagerComponent.vue?vue&type=style&index=0&id=940f94f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940f94f6",
  null
  
)

export default component.exports