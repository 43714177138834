












































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import {HintSection} from "@/models/entities/hint/hintSection";

@Component
export default class HintSectionComponent extends Vue{
  protected hintSections: Array<HintSection> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.HintSection.listAsync().then((hintSections: Array<HintSection>) => {
      this.hintSections = hintSections;
    });
  }

  async saveAsync(hintSection: HintSection) {
    if (hintSection.created)
      await this.createAsync(hintSection);
    else if (hintSection.updated)
      await this.editAsync(hintSection);
  }

  async deleteAsync(hintSection: HintSection) {
    if (this.hintSections) {
      if (hintSection.created)
        this.hintSections = this.hintSections.filter(x => x != hintSection);
      else
        await this.removeAsync(hintSection);
    }
  }

  async createAsync(hintSection: HintSection) {
    try {
      const createdHintSection = await Services.HintSection.createAsync(hintSection);

      this.hintSections.splice(this.hintSections.indexOf(hintSection), 1, createdHintSection);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(hintSection: HintSection) {
    try {
      await Services.HintSection.editAsync(hintSection);

      this.setUpdateTimeout(true);

      hintSection.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(hintSection: HintSection) {
    try {
      await Services.HintSection.removeAsync(hintSection);

      this.hintSections = this.hintSections.filter(x => x != hintSection);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const hintSection = new HintSection(
        v4(),
        ''
    );

    hintSection.created = true;
    hintSection.updated = true;

    this.hintSections.push(hintSection);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

