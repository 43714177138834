




















































































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";
import WarehouseBrandRemainsLengths from "@/models/entities/remainsLengths/warehouseBrandRemainsLengths";
import DefaultRemainsLengths from "@/models/entities/remainsLengths/defaultRemainsLengths";
import Repositories from "@/repositories";
import Warehouse from "@/models/entities/warehouse/warehouse";
import {Brand} from "@/models/entities/product/cable/brand";

@Component
export default class RemainsLengthsComponent extends Vue{
  protected defaultRemainsLengths: DefaultRemainsLengths | null = null;
  protected warehouseBrandRemainsLengths: Array<WarehouseBrandRemainsLengths> = [];
  protected warehouses: Array<Warehouse> = [];
  protected brands: Array<Brand> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.RemainsLengths.getDefaultAsync().then((remainsLengths: DefaultRemainsLengths) => {
      this.defaultRemainsLengths = remainsLengths;
    });
    await Services.RemainsLengths.listAsync().then((remainsLengths: Array<WarehouseBrandRemainsLengths>) => {
      this.warehouseBrandRemainsLengths = remainsLengths;
    });
    this.warehouses = await Repositories.Warehouse.listAsync();
    this.brands = await Repositories.Brand.listAsync();
  }

  async saveAsync(remainsLengths: WarehouseBrandRemainsLengths) {
    if (remainsLengths.created)
      await this.createAsync(remainsLengths);
    else if (remainsLengths.updated)
      await this.editAsync(remainsLengths);
  }

  async deleteAsync(remainsLengths: WarehouseBrandRemainsLengths) {
    if (this.warehouseBrandRemainsLengths) {
      if (remainsLengths.created)
        this.warehouseBrandRemainsLengths = this.warehouseBrandRemainsLengths.filter(x => x != remainsLengths);
      else
        await this.removeAsync(remainsLengths);
    }
  }

  async createAsync(remainsLengths: WarehouseBrandRemainsLengths) {
    try {
      const createdRemainsLengths = await Services.RemainsLengths.createAsync(remainsLengths);

      this.warehouseBrandRemainsLengths.splice(this.warehouseBrandRemainsLengths.indexOf(remainsLengths), 1, createdRemainsLengths);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(remainsLengths: WarehouseBrandRemainsLengths) {
    try {
      await Services.RemainsLengths.editAsync(remainsLengths);

      this.setUpdateTimeout(true);

      remainsLengths.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editDefaultAsync(remainsLengths: DefaultRemainsLengths) {
    try {
      await Services.RemainsLengths.editDefaultAsync(remainsLengths);

      this.setUpdateTimeout(true);

      remainsLengths.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(remainsLengths: WarehouseBrandRemainsLengths) {
    try {
      await Services.RemainsLengths.removeAsync(remainsLengths);

      this.warehouseBrandRemainsLengths = this.warehouseBrandRemainsLengths.filter(x => x != remainsLengths);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const remainsLengths = new WarehouseBrandRemainsLengths(
        v4(),
        null,
        null,
        10000,
        50000
    );

    remainsLengths.created = true;
    remainsLengths.updated = true;

    this.warehouseBrandRemainsLengths.push(remainsLengths);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

