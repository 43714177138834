import {PictureFileTypes} from "@/models/entities/picture/pictureFileTypes";
import moment from "moment";

export interface PictureDto{
    id: number;
    uploadedAtUts: string;
    type: PictureFileTypes;
    contentType: string;
    size: number;
    fileName: string;
}

export class Picture {
    constructor(
        public readonly id: number,
        public readonly uploadedAtUts: moment.Moment,
        public readonly type: PictureFileTypes,
        public readonly contentType: string,
        public readonly size: number,
        public readonly fileName: string
    ) {
    }

    public static fromDto(dto: PictureDto): Picture{
        return new Picture(
            dto.id,
            moment.utc(dto.uploadedAtUts),
            dto.type,
            dto.contentType,
            dto.size,
            dto.fileName
        )
    }
}