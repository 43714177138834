import moment from "moment";
import ValidatorService from "@/services/ValidatorService";
import {Category, CategoryDto} from "@/models/entities/product/cable/category";
import {Brand, BrandDto} from "@/models/entities/product/cable/brand";
import {ShortPicture, ShortPictureDto} from "@/models/entities/picture/shortPicture";
import {Shell, ShellDto} from "@/models/entities/product/cable/shell";

export interface ExclusiveCategoryDto{
    id: number | string;
    description: string;
    descriptionLink: string;
    category: CategoryDto;
    brand: BrandDto;
    pageImage: ShortPictureDto;
    logo: ShortPictureDto;
    createdAtUtc: string;
    shells: Array<ShellDto>;
}

export class ExclusiveCategory{
    public picturesLoaded = false;

    public created = false;

    public descriptionUpdated = false;
    public descriptionLinkUpdated = false;
    public categoryUpdated = false;
    public brandUpdated = false;
    public logoUpdated = false;
    public pageImageUpdated = false;
    public shellsUpdated = false;

    constructor(
        public readonly id: number | string,
        public _description: string,
        public _descriptionLink: string,
        public _category: Category | null,
        public _brand: Brand | null,
        public _pageImage: ShortPicture | null,
        public _logo: ShortPicture | null,
        public readonly createdAtUtc: moment.Moment,
        public _shells: Array<Shell>
    ) {
    }

    public static fromDto(dto: ExclusiveCategoryDto): ExclusiveCategory{
        return new ExclusiveCategory(
            dto.id,
            dto.description,
            dto.descriptionLink,
            Category.fromDto(dto.category),
            Brand.fromDto(dto.brand),
            ShortPicture.fromDto(dto.pageImage),
            ShortPicture.fromDto(dto.logo),
            moment.utc(dto.createdAtUtc),
            dto.shells.map(x => Shell.fromDto(x))
        )
    }


    get description(): string{
        return this._description;
    }

    set description(value: string){
        this._description = value;
        this.descriptionUpdated = true;
    }

    get descriptionLink(): string{
        return this._descriptionLink;
    }

    set descriptionLink(value: string){
        this._descriptionLink = value;
        this.descriptionLinkUpdated = true;
    }

    get category(): Category | null {
        return this._category;
    }

    set category(value: Category | null) {
        this._category = value;
        this.categoryUpdated = true;
    }

    get brand(): Brand | null {
        return this._brand;
    }

    set brand(value: Brand | null) {
        this._brand = value;
        this.brandUpdated = true;
    }

    get pageImage(): ShortPicture | null {
        return this._pageImage;
    }

    set pageImage(value: ShortPicture | null) {
        this._pageImage = value;
        this.pageImageUpdated = true;
    }

    get logo(): ShortPicture | null {
        return this._logo;
    }

    set logo(value: ShortPicture | null) {
        this._logo = value;
        this.logoUpdated = true;
    }

    get shells(): Array<Shell> {
        return this._shells;
    }

    set shells(value: Array<Shell>) {
        this._shells = value;
        this.shellsUpdated = true;
    }


    get updated(): boolean {
        return this.descriptionUpdated ||
            this.descriptionLinkUpdated ||
            this.categoryUpdated ||
            this.brandUpdated ||
            this.pageImageUpdated ||
            this.logoUpdated ||
            this.shellsUpdated;
    }

    set updated(value: boolean) {
        this.descriptionUpdated = value;
        this.descriptionLinkUpdated = value;
        this.categoryUpdated = value;
        this.brandUpdated = value;
        this.pageImageUpdated = value;
        this.logoUpdated = value;
        this.shellsUpdated = value;
    }

    get valid(): boolean {
        return this.descriptionValid &&
            this.descriptionLinkValid &&
            this.categoryValid &&
            this.brandValid &&
            this.pageImageValid &&
            this.logoValid &&
            this.shellsValid;
    }

    get descriptionValid(): boolean {
        return ValidatorService.isNotEmpty(this.description);
    }

    get descriptionLinkValid(): boolean {
        return ValidatorService.isLengthInRange(this.descriptionLink, 1, 255);
    }

    get categoryValid(): boolean {
        return this.category !== null;
    }

    get brandValid(): boolean {
        return this.brand !== null;
    }

    get pageImageValid(): boolean {
        return this.pageImage !== null;
    }

    get logoValid(): boolean {
        return this.logo !== null;
    }

    get shellsValid(): boolean {
        return this.shells.length > 0;
    }
}