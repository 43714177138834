




























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import LayoutService from "@/services/LayoutService";
import Reel from "@/models/entities/product/reel/reel";
import ApplicationError from "@/models/errors/ApplicationError";

@Component
export default class RefillComponent extends Vue{
  protected searchString = '';

  protected reels: Array<Reel> = [];
  protected filteredReels: Array<Reel> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Reel.listAsync().then((reels: Array<Reel>) => {
      this.searchString = '';
      this.reels = reels;
      this.filteredReels = reels;
    });
  }

  filterReels(){
    if (this.searchString){
      this.filteredReels = this.filteredReels.filter(x => x.number.includes(this.searchString.trim()));
    }
    else{
      this.filteredReels = this.reels;
    }
  }

  async uploadReelsAsync(event: Event): Promise<void> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        try{
          await Services.File.importReelsAsync(file);
          await this.loadAsync();
          this.setUpdateTimeout(true);
        } catch (error) {
          this.errorMessages.splice(0, this.errorMessages.length);
          if (error instanceof ApplicationError)
            this.errorMessages.push(error.message)
          else
            this.errorMessages.push('Неизвестная ошибка');
          this.setUpdateTimeout(false);
        }
        target.value = '';
      }
    }
  }
  
  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

