import {ServiceBase} from "@/services/ServiceBase";
import WarehouseBrandRemainsLengths, {WarehouseBrandRemainsLengthsDto} from "@/models/entities/remainsLengths/warehouseBrandRemainsLengths";
import DefaultRemainsLengths from "@/models/entities/remainsLengths/defaultRemainsLengths";
import {RemainsLengthsDto} from "@/models/entities/remainsLengths/remainsLengths";

export default class RemainsLengthsService extends ServiceBase {
    constructor() {
        super();
    }

    async getDefaultAsync(): Promise<DefaultRemainsLengths> {
        return await this.postRequestWithResultAsync(
            `/api/RemainsLengths/GetDefault`,
            {},
            (data: RemainsLengthsDto) => DefaultRemainsLengths.fromDto(data));
    }

    async listAsync(): Promise<Array<WarehouseBrandRemainsLengths>> {
        return  await this.postRequestWithResultAsync(
            `/api/RemainsLengths/List`,
            {},
            (data: WarehouseBrandRemainsLengthsDto[]) => Promise.all(data.map(async x => await WarehouseBrandRemainsLengths.fromDto(x))));
    }

    async createAsync(remainsLengths: WarehouseBrandRemainsLengths): Promise<WarehouseBrandRemainsLengths> {
        return await this.postRequestWithResultAsync(
            `/api/RemainsLengths/Create`,
            {
                brand: {
                    id: remainsLengths.brand?.id
                },
                warehouse: {
                    id: remainsLengths.warehouse?.id
                },
                minTotalLength: remainsLengths.minTotalLength,
                optimalTotalLength: remainsLengths.optimalTotalLength
            },
            (data: WarehouseBrandRemainsLengthsDto) => WarehouseBrandRemainsLengths.fromDto(data));
    }

    async editAsync(remainsLengths: WarehouseBrandRemainsLengths): Promise<void> {
        return await this.postRequestAsync(
            `/api/RemainsLengths/Edit`,
            {
                id: remainsLengths.id,
                brand: {
                    id: remainsLengths.brand?.id
                },
                warehouse: {
                    id: remainsLengths.warehouse?.id
                },
                minTotalLength: remainsLengths.minTotalLength,
                optimalTotalLength: remainsLengths.optimalTotalLength
            });
    }

    async editDefaultAsync(remainsLengths: DefaultRemainsLengths): Promise<void> {
        return await this.postRequestAsync(
            `/api/RemainsLengths/EditDefault`,
            {
                minTotalLength: remainsLengths.minTotalLength,
                optimalTotalLength: remainsLengths.optimalTotalLength
            });
    }

    async removeAsync(remainsLengths: WarehouseBrandRemainsLengths): Promise<void> {
        return await this.postRequestAsync(
            `/api/RemainsLengths/Remove`,
            {
                id: remainsLengths.id
            });
    }
}