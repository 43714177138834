import Vue from 'vue'
import Vuex from 'vuex'
import {RootState} from "@/store/types/RootState";
import {getters} from "@/store/getters";
import {mutations} from "@/store/mutations";
import {actions} from "@/store/actions";
import {modules} from "@/store/modules";

Vue.use(Vuex);

export const state: RootState = {
    profile: null,
    brands: null,
    warehouses: null
};

const store = new Vuex.Store<RootState>({
    state,
    getters,
    actions,
    mutations,
    modules
});

export default store;