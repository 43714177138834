import {ServiceBase} from "@/services/ServiceBase";
import {ShortManufacturerPrice, ShortManufacturerPriceDto} from "@/models/entities/price/shortManufacturerPrice";

export default class ManufacturerPriceService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(priceId: number): Promise<Array<ShortManufacturerPrice>> {
        return await this.postRequestWithResultAsync(
            `/api/ManufacturerPrice/List`,
            {
                priceId: priceId
            },
            (data: ShortManufacturerPriceDto[]) => data.map(x => ShortManufacturerPrice.fromDto(x)));
    }

    async createAsync(price: ShortManufacturerPrice, priceId: number): Promise<ShortManufacturerPrice> {
        return await this.postRequestWithResultAsync(
            `/api/ManufacturerPrice/Create`,
            {
                priceId: priceId,
                manufacturerId: price.manufacturer.id
            },
            (data: ShortManufacturerPriceDto) => ShortManufacturerPrice.fromDto(data));
    }

    async editAsync(price: ShortManufacturerPrice): Promise<void> {
        return await this.postRequestAsync(
            `/api/ManufacturerPrice/Edit`,
            {
                id: price.id,
                manufacturerId: price.manufacturer.id
            });
    }

    async removeAsync(price: ShortManufacturerPrice): Promise<void> {
        return await this.postRequestAsync(
            `/api/ManufacturerPrice/Remove`,
            {
                id: price.id
            });
    }
}