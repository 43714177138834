import Warehouse from "@/models/entities/warehouse/warehouse";
import Services from "@/services";
import store from "@/store";

export default class WarehouseRepository{

    async getAsync(id: string | number): Promise<Warehouse> {
        if (store.getters.warehouses === null){
            await this.updateAsync();
        }

        return store.getters.warehouses.find((x: Warehouse) => x.id == id);
    }

    async listAsync(): Promise<Array<Warehouse>> {
        if (store.getters.warehouses === null){
            await this.updateAsync();
        }

        return store.getters.warehouses;
    }


    async updateAsync(): Promise<void>{
        const warehouses = await Services.Warehouse.listAsync();
        await store.dispatch('updateWarehouses', warehouses);
    }
}