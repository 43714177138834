import {RemainsLengths, RemainsLengthsDto} from "@/models/entities/remainsLengths/remainsLengths";
import {Brand} from "@/models/entities/product/cable/brand";
import Warehouse from "@/models/entities/warehouse/warehouse";
import Repositories from "@/repositories";

export interface WarehouseBrandRemainsLengthsDto extends RemainsLengthsDto{
    brandId: number;
    warehouseId: number;
}

export default class WarehouseBrandRemainsLengths extends RemainsLengths{
    public brandUpdated = false;
    public warehouseUpdated = false;
    
    constructor(
        id: number | string,
        public _brand: Brand | null,
        public _warehouse: Warehouse | null,
        minTotalLength: number,
        optimalTotalLength: number
    ) {
        super(id, minTotalLength, optimalTotalLength);
    }

    public static async fromDto(dto: WarehouseBrandRemainsLengthsDto): Promise<WarehouseBrandRemainsLengths> {
        return new WarehouseBrandRemainsLengths(
            dto.id,
            await Repositories.Brand.getAsync(dto.brandId),
            await Repositories.Warehouse.getAsync(dto.warehouseId),
            dto.minTotalLength,
            dto.optimalTotalLength
        )
    }

    get brand(): Brand | null{
        return this._brand;
    }

    set brand(value: Brand | null){
        this._brand = value;
        this.brandUpdated = true;
    }

    get warehouse(): Warehouse | null{
        return this._warehouse;
    }

    set warehouse(value: Warehouse | null){
        this._warehouse = value;
        this.warehouseUpdated = true;
    }

    get updated(): boolean {
        return super.updated ||
            this.brandUpdated ||
            this.warehouseUpdated;
    }

    set updated(value: boolean) {
        super.updated = value;
        this.brandUpdated = value;
        this.warehouseUpdated = value;
    }

    get valid(): boolean {
        return super.valid &&
            this.brandValid &&
            this.warehouseValid;
    }

    get brandValid(): boolean {
        return this._brand !== null;
    }

    get warehouseValid(): boolean {
        return this._warehouse !== null;
    }
}