






























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import LayoutService from "@/services/LayoutService";
import Reel from "@/models/entities/product/reel/reel";

@Component
export default class WriteOffComponent extends Vue{
  protected searchString = '';

  protected reels: Array<Reel> = [];
  protected filteredReels: Array<Reel> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Reel.listAsync().then((reels: Array<Reel>) => {
      this.searchString = '';
      this.reels = reels;
      this.filteredReels = reels;
    });
  }
  
  filterReels(){
    if (this.searchString){
      this.filteredReels = this.filteredReels.filter(x => x.number.includes(this.searchString.trim()));
    }
    else{
      this.filteredReels = this.reels;
    }
  }

  async deleteAsync(reel: Reel) {
    if (this.reels) {
        await this.removeAsync(reel);
    }
  }

  async removeAsync(reel: Reel) {
    try {
      await Services.Reel.removeAsync(reel);

      await this.loadAsync();

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

