import {ServiceBase} from "@/services/ServiceBase";
import {CityExtraCharge, CityExtraChargeDto} from "@/models/entities/cityExtraCharge/cityExtraCharge";

export default class CityExtraChargeService extends ServiceBase{
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<CityExtraCharge>> {
        return await this.postRequestWithResultAsync(
            `/api/CityExtraCharge/List`,
            {
            },
            (data: CityExtraChargeDto[]) => data.map(x => CityExtraCharge.fromObject(x)));
    }

    async createAsync(charge: CityExtraCharge): Promise<CityExtraCharge> {
        return await this.postRequestWithResultAsync(
            `/api/CityExtraCharge/Create`,
            {
                extraRate: charge.extraRate,
                city: {
                    id: charge.city.id
                }
            },
            (data: CityExtraChargeDto) => CityExtraCharge.fromObject(data));
    }

    async editAsync(charge: CityExtraCharge): Promise<void> {
        return await this.postRequestAsync(
            `/api/CityExtraCharge/Edit`,
            {
                id: charge.id,
                extraRate: charge.extraRate,
                city: {
                    id: charge.city.id
                }
            });
    }

    async removeAsync(charge: CityExtraCharge): Promise<void> {
        return await this.postRequestAsync(
            `/api/CityExtraCharge/Remove`,
            {
                id: charge.id
            });
    }
}