
























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import LayoutService from "@/services/LayoutService";
import {InventoryReport} from "@/models/entities/inventoryReport/inventoryReport";
import ApplicationError from "@/models/errors/ApplicationError";

@Component
export default class InventoryComponent extends Vue {
  protected inventoryReports: Array<InventoryReport> = [];

  protected newInventoryReportRequested = false;
  protected newInventoryReport: InventoryReport | null = null;

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.InventoryReport.listAsync().then((inventoryReports: Array<InventoryReport>) => {
      this.inventoryReports = inventoryReports;
    });
  }

  async updateAsync() {
    if (!this.newInventoryReportRequested){
      try {
        this.newInventoryReportRequested = true;
        await Services.InventoryReport.updateAsync().then((inventoryReport: InventoryReport) => {
          this.newInventoryReport = inventoryReport;
          this.inventoryReports.unshift(this.newInventoryReport);
        });
        this.newInventoryReportRequested = false;
        this.setUpdateTimeout(true);
      } catch (error) {
        this.errorMessages.splice(0, this.errorMessages.length);
        if (error instanceof ApplicationError)
          this.errorMessages.push(error.message)
        else
          this.errorMessages.push('Неизвестная ошибка');
        this.setUpdateTimeout(false);
      }
      this.newInventoryReportRequested = false;
    }
  }

  async uploadInventoryAsync(event: Event): Promise<void> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        try{
          await Services.File.importInventoryAsync(file);
          await this.loadAsync();
          this.setUpdateTimeout(true);
        } catch (error) {
          this.errorMessages.splice(0, this.errorMessages.length);
          if (error instanceof ApplicationError)
            this.errorMessages.push(error.message)
          else
            this.errorMessages.push('Неизвестная ошибка');
          this.setUpdateTimeout(false);
        }
        target.value = '';
      }
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 30 * 1000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

