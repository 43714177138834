








import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";

@Component
export default class ProfileComponent extends Vue {
  public success = true;
  
  get isProfileLoaded(): boolean {
    return this.$store.getters.isProfileLoaded;
  }

  get profileLogin(): boolean {
    return this.$store.getters.profileLogin;
  }

  async logoutAsync() {
    try {
      await Services.Account.logoutAsync();

      await this.$store.dispatch('clearProfile');
      
      await this.$router.push('login');
    } catch (Error) {
      this.success = false;
    }
  }
}
