import ValidatorService from "@/services/ValidatorService";

export interface ReelHoldingTimeSpanDto{
    id: number;
    days: number;
}

export default abstract class ReelHoldingTimeSpan{
    public created = false;

    public daysUpdated = false;
    
    protected constructor(
        public readonly id: number | string,
        public _days: number
    ) {
    }

    get days(): number{
        return this._days;
    }

    set days(value: number){
        this._days = value;
        this.daysUpdated = true;
    }

    get updated(): boolean {
        return this.daysUpdated;
    }

    set updated(value: boolean) {
        this.daysUpdated = value;
    }

    get valid(): boolean {
        return this.daysValid;
    }

    get daysValid(): boolean {
        return ValidatorService.isNumberInRange(this._days, 1, 365);
    }
}