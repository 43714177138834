import {ServiceBase} from "@/services/ServiceBase";
import Manufacturer, {ManufacturerDto} from "@/models/entities/manufacturer/manufacturer";

export default class ManufacturerService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Manufacturer>> {
        return await this.postRequestWithResultAsync(
            '/api/Manufacturer/List',
            {},
            (dto: ManufacturerDto[]) => dto.map(x => Manufacturer.fromDto(x)));
    }
}