import {ServiceBase} from "@/services/ServiceBase";

export default class AccountService extends ServiceBase {
    constructor() {
        super();
    }

    async loginAsync(login: string, password: string): Promise<void> {
        await this.postRequestAsync(
            '/api/Account/Login',
            {
                login,
                password
            });
    }

    async logoutAsync(): Promise<void> {
        await this.postRequestAsync(
            '/api/Account/Logout',
            {
            });
    }
}