

















































import {Component, Vue} from "vue-property-decorator";
import Logist from "@/models/entities/logist/logist";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";

@Component
export default class LogistComponent extends Vue {
  protected logists: Array<Logist> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Logist.listAsync().then((logists: Array<Logist>) => {
      this.logists = logists;
    });
  }

  async saveAsync(logist: Logist) {
    if (logist.created)
      await this.createAsync(logist);
    else if (logist.updated)
      await this.editAsync(logist);
  }

  async deleteAsync(logist: Logist) {
    if (this.logists &&
        this.logists.length > 1) {
      if (logist.created)
        this.logists = this.logists.filter(x => x != logist);
      else
        await this.removeAsync(logist);
    }
  }

  async createAsync(logist: Logist) {
    try {
      const createdLogist = await Services.Logist.createAsync(logist);

      this.logists.splice(this.logists.indexOf(logist), 1, createdLogist);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(logist: Logist) {
    try {
      await Services.Logist.editAsync(logist);

      this.setUpdateTimeout(true);

      logist.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(logist: Logist) {
    try {
      await Services.Logist.removeAsync(logist);

      this.logists = this.logists.filter(x => x != logist);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const logist = new Logist(
        v4(),
        '',
        '',
        moment.utc()
    );

    logist.created = true;
    logist.updated = true;

    this.logists.push(logist);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

