import {ServiceBase} from "@/services/ServiceBase";
import {Brand, BrandDto} from "@/models/entities/product/cable/brand";

export default class BrandService extends ServiceBase{
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Brand>> {
        return await this.postRequestWithResultAsync(
            `/api/Brand/List`,
            {},
            (dtos: BrandDto[]) => dtos.map(x => Brand.fromDto(x)));
    }

    async createAsync(brand: Brand): Promise<Brand> {
        return await this.postRequestWithResultAsync(
            `/api/Brand/Create`,
            {
                name: brand.name,
                latinName: brand.latinName,
                description: brand.description,
                descriptionLink: brand.descriptionLink,
                minOrderLength: brand.minOrderLength,
                minLengthOnDrum: brand.minLengthOnDrum,
                categoryId: brand.category.id,
                logoId: brand.logo?.id,
                pageImageId: brand.pageImage?.id
            },
            (dto: BrandDto) => Brand.fromDto(dto));
    }

    async editAsync(brand: Brand): Promise<void> {
        return await this.postRequestAsync(
            `/api/Brand/Edit`,
            {
                id: brand.id,
                name: brand.name,
                latinName: brand.latinName,
                description: brand.description,
                descriptionLink: brand.descriptionLink,
                minOrderLength: brand.minOrderLength,
                minLengthOnDrum: brand.minLengthOnDrum,
                categoryId: brand.category.id,
                logoId: brand.logo?.id,
                pageImageId: brand.pageImage?.id
            });
    }

    async removeAsync(brand: Brand): Promise<void> {
        return await this.postRequestAsync(
            `/api/Brand/Remove`,
            {
                id: brand.id
            });
    }
}