import {RemainsLengths, RemainsLengthsDto} from "@/models/entities/remainsLengths/remainsLengths";

export default class DefaultRemainsLengths extends RemainsLengths {
    constructor(
        id: number,
        minTotalLength: number,
        optimalTotalLength: number
    ) {
        super(id, minTotalLength, optimalTotalLength);
    }

    public static fromDto(dto: RemainsLengthsDto): DefaultRemainsLengths {
        return new DefaultRemainsLengths(
            dto.id,
            dto.minTotalLength,
            dto.optimalTotalLength
        )
    }
}