import ValidatorService from "@/services/ValidatorService";

export interface RemainsLengthsDto{
    id: number;
    minTotalLength: number;
    optimalTotalLength: number;
}

export abstract class RemainsLengths {
    public created = false;

    public minTotalLengthUpdated = false;
    public optimalTotalLengthUpdated = false;

    protected constructor(
        public readonly id: number | string,
        public _minTotalLength: number,
        public _optimalTotalLength: number
    ) {
    }

    get minTotalLength(): number{
        return this._minTotalLength;
    }

    set minTotalLength(value: number){
        this._minTotalLength = value;
        this.minTotalLengthUpdated = true;
    }

    get optimalTotalLength(): number{
        return this._optimalTotalLength;
    }

    set optimalTotalLength(value: number){
        this._optimalTotalLength = value;
        this.optimalTotalLengthUpdated = true;
    }

    get updated(): boolean {
        return this.minTotalLengthUpdated ||
            this.optimalTotalLengthUpdated;
    }

    set updated(value: boolean) {
        this.minTotalLengthUpdated = value;
        this.optimalTotalLengthUpdated = value;
    }

    get valid(): boolean {
        return this.minTotalLengthValid &&
            this.optimalTotalLengthValid;
    }

    get minTotalLengthValid(): boolean {
        return ValidatorService.isNumberInRange(this.minTotalLength, 2000, 100000);
    }

    get optimalTotalLengthValid(): boolean {
        return ValidatorService.isNumberInRange(this.optimalTotalLength, 2000, 100000);
    }
}