import {ServiceBase} from "@/services/ServiceBase";
import {HintLink, HintLinkDto} from "@/models/entities/hint/hintLink";
import {Hint} from "@/models/entities/hint/hint";

export default class HintLinkService extends ServiceBase {
    constructor() {
        super();
    }

    async removeAsync(hintLink: HintLink): Promise<void> {
        return await this.postRequestAsync(
            `/api/HintLink/Remove`,
            {
                id: hintLink.id
            });
    }

    async editAsync(hintLink: HintLink): Promise<void> {
        return await this.postRequestAsync(
            `/api/HintLink/Edit`,
            {
                id: hintLink.id,
                url: hintLink.url
            });
    }

    async createAsync(hint: Hint, hintLink: HintLink): Promise<HintLink> {
        return await this.postRequestWithResultAsync(
            `/api/HintLink/Create`,
            {
                hintId: hint.id,
                url: hintLink.url
            },
            (dto: HintLinkDto) => HintLink.fromDto(dto));
    }
}