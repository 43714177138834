export interface BankDetailsDto {
    bik: string;
    bankName: string;
    correspondentAccount: string;
    transactionalAccount: string;
}

export class BankDetails {
    constructor(
        public bik: string,
        public bankName: string,
        public correspondentAccount: string,
        public transactionalAccount: string
    ) {
    }

    public static FromObject(bankDetails: BankDetailsDto): BankDetails{
        return new BankDetails(
            bankDetails.bik,
            bankDetails.bankName,
            bankDetails.correspondentAccount,
            bankDetails.transactionalAccount
        )
    }
}