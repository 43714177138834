import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route} from 'vue-router'

import HomeComponent from "@/components/HomeComponent.vue";
import DefaultPaymentsConditionComponent from "@/components/DefaultPaymentsConditionComponent.vue";
import OurFirmComponent from "@/components/OurFirmComponent.vue";
import LogistComponent from "@/components/LogistComponent.vue";
import FacilityManagerComponent from "@/components/FacilityManagerComponent.vue";
import FacilityInspectorComponent from "@/components/FacilityInspectorComponent.vue";
import CityComponent from "@/components/CityComponent.vue";
import CityExtraChargeComponent from "@/components/CityExtraChargeComponent.vue";
import CategoryComponent from "@/components/CategoryComponent.vue";
import BrandComponent from "@/components/BrandComponent.vue";
import LoginComponent from "@/components/LoginComponent.vue";
import RemainsLengthsComponent from "@/components/RemainsLengthsComponent.vue";
import store from "@/store";
import Services from "@/services";
import {Profile} from "@/models/entities/profile/profile";
import ReelHoldingTimeSpanComponent from "@/components/ReelHoldingTimeSpanComponent.vue";
import InventoryComponent from "@/components/InventoryComponent.vue";
import PriceComponent from "@/components/PriceComponent.vue";
import ManufacturerPriceComponent from "@/components/ManufacturerPriceComponent.vue";
import HintSectionComponent from "@/components/HintSectionComponent.vue";
import HintComponent from "@/components/HintComponent.vue";
import HintHtmlComponent from "@/components/HintHtmlComponent.vue";
import PictureComponent from "@/components/HintImageComponent.vue";
import ShellComponent from "@/components/ShellComponent.vue";
import WriteOffComponent from "@/components/WriteOffComponent.vue";
import RefillComponent from "@/components/RefillComponent.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginComponent,
    },
    {
        path: '/defaultPaymentsCondition',
        name: 'defaultPaymentsCondition',
        component: DefaultPaymentsConditionComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ourFirm',
        name: 'ourFirm',
        component: OurFirmComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/logist',
        name: 'logist',
        component: LogistComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/facilityManager',
        name: 'facilityManager',
        component: FacilityManagerComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/facilityInspector',
        name: 'facilityInspector',
        component: FacilityInspectorComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cities',
        name: 'cities',
        component: CityComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cityExtraCharge',
        name: 'cityExtraCharge',
        component: CityExtraChargeComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/category',
        name: 'category',
        component: CategoryComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/brand',
        name: 'brand',
        component: BrandComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/remainsLengths',
        name: 'remainsLengths',
        component: RemainsLengthsComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reelHoldingTimeSpan',
        name: 'reelHoldingTimeSpan',
        component: ReelHoldingTimeSpanComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: InventoryComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/price',
        name: 'price',
        component: PriceComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/price/:id',
        name: 'manufacturerPrice',
        component: ManufacturerPriceComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hintSections',
        name: 'hintSections',
        component: HintSectionComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hints',
        name: 'hints',
        component: HintComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hint/:id',
        name: 'hint',
        component: HintHtmlComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hintImages',
        name: 'hintImages',
        component: PictureComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/shell',
        name: 'shell',
        component: ShellComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/writeOff',
        name: 'writeOff',
        component: WriteOffComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/refill',
        name: 'refill',
        component: RefillComponent,
        meta: {
            requiresAuth: true
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.matched.some(x => x.meta.requiresAuth)) {
        if (store.getters.isProfileLoaded) {
            next();
        } else {
            try {
                const profile: Profile = await Services.Profile.getAsync();

                await store.dispatch('updateProfile', profile);

                next();
            } catch (Error) {
                next('login');
            }
        }
    } else {
        next();
    }
});

export default router