export interface ErrorDataViewModel {
    description: string;
    type: number;
    code: number;
}

export class Error {
    constructor(
        public readonly description: string,
        public readonly type: number,
        public readonly code: number) {
    }

    public static fromObject(data: ErrorDataViewModel): Error {
        return new Error(data.description, data.type, data.code);
    }
}