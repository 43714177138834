import moment from "moment";
import {Employee, EmployeeDto} from "@/models/entities/common/employee";

export interface FacilityManagerDto extends EmployeeDto{
    firmsCount: number;
}

export default class FacilityManager extends Employee {
    public static entityName = 'FacilityManager';

    constructor(
        id: number | string,
        name: string,
        email: string,
        createdAtUtc: moment.Moment,
        public readonly firmsCount: number
    ) {
        super(id, name, email, createdAtUtc)
    }

    public static fromObject(dto: FacilityManagerDto): FacilityManager {
        return new FacilityManager(
            dto.id,
            dto.name,
            dto.email,
            moment.utc(dto.createdAtUtc),
            dto.firmsCount)
    }
}