import {ServiceBase} from "@/services/ServiceBase";
import {OurFirm, OurFirmDto} from "@/models/entities/ourFirm/ourFirm";

export default class OurFirmService extends ServiceBase {
    constructor() {
        super();
    }

    async getAsync(): Promise<OurFirm> {
        return await this.postRequestWithResultAsync(
            '/api/OurFirm/Get',
            {},
            (data: OurFirmDto) => OurFirm.FromObject(data));
    }

    async editAsync(ourFirm: OurFirm): Promise<void> {
        return await this.postRequestAsync(
            '/api/OurFirm/Edit',
            {
                ourFirm: {
                    email: ourFirm.email,
                    documentationEmail: ourFirm.documentationEmail,
                    name: ourFirm.name,
                    inn: ourFirm.inn,
                    kpp: ourFirm.kpp,
                    legalAddress: ourFirm.legalAddress,
                    postalAddress: ourFirm.postalAddress,
                    bankDetails: {
                        bik: ourFirm.bik,
                        bankName: ourFirm.bankName,
                        correspondentAccount: ourFirm.correspondentAccount,
                        transactionalAccount: ourFirm.transactionalAccount,
                    },
                    phone: ourFirm.phone,
                    webSite: ourFirm.webSite,
                    directorName: ourFirm.directorName,
                    accountantName: ourFirm.accountantName,
                    responsibleName: ourFirm.responsibleName
                }
            });
    }
}