import {ServiceBase} from "@/services/ServiceBase";
import {Profile, ProfileDto} from "@/models/entities/profile/profile";

export default class ProfileService extends ServiceBase {
    constructor() {
        super();
    }

    async getAsync(): Promise<Profile> {
        return await this.postRequestWithResultAsync(
            '/api/Profile/Get',
            {
            },
            (dto: ProfileDto) => Profile.fromDto(dto));
    }
}