import moment from "moment";
import {ShortPicture, ShortPictureDto} from "../../picture/shortPicture";
import ValidatorService from "@/services/ValidatorService";

export interface CategoryDto{
    id: number | string;
    name: string;
    createdAtUtc: string;
    icon: ShortPictureDto;
}

export class Category{
    public picturesLoaded = false;
    
    public created = false;
    
    public nameUpdated = false;
    public iconUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string,
        public readonly createdAtUtc: moment.Moment,
        public _icon: ShortPicture | null
    ) {
    }

    public static fromDto(dto: CategoryDto): Category{
        return new Category(
            dto.id,
            dto.name,
            moment.utc(dto.createdAtUtc),
            ShortPicture.fromDto(dto.icon)
        )
    }


    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get icon(): ShortPicture | null{
        return this._icon;
    }

    set icon(value: ShortPicture | null){
        this._icon = value;
        this.iconUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.iconUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.iconUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid &&
            this.iconValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }

    get iconValid(): boolean {
        return this.icon !== null;
    }
}