import {HintSection, HintSectionDto} from "@/models/entities/hint/hintSection";
import ValidatorService from "@/services/ValidatorService";
import {Roles} from "@/models/entities/hint/roles";
import {HintLink, HintLinkDto} from "@/models/entities/hint/hintLink";

export interface HintDto {
    id: number;
    name: string;
    hintSection: HintSectionDto;
    role: Roles;
    html: string | null;
    links: Array<HintLinkDto> | null;
}

export class Hint{
    public created = false;

    public nameUpdated = false;
    public hintSectionUpdated = false;
    public htmlUpdated = false;
    public urlsUpdated = false;
    public roleUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string,
        public _hintSection: HintSection,
        public _role: Roles,
        public _html: string | null,
        public _links: Array<HintLink> | null
    ) {
    }

    public static fromDto(dto: HintDto): Hint{
        return new Hint(
            dto.id,
            dto.name,
            HintSection.fromDto(dto.hintSection),
            dto.role,
            dto.html,
            dto.links ? dto.links.map(x => HintLink.fromDto(x)) : null
        )
    }

    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get hintSection(): HintSection{
        return this._hintSection;
    }

    set hintSection(value: HintSection){
        this._hintSection = value;
        this.hintSectionUpdated = true;
    }

    get html(): string | null{
        return this._html;
    }

    set html(value: string | null){
        this._html = value;
        this.htmlUpdated = true;
    }

    get links(): Array<HintLink> | null{
        return this._links;
    }

    set links(value: Array<HintLink> | null){
        this._links = value;
        this.urlsUpdated = true;
    }

    get role(): Roles{
        return this._role;
    }

    set role(value: Roles){
        this._role = value;
        this.roleUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.hintSectionUpdated ||
            this.htmlUpdated ||
            this.urlsUpdated ||
            this.roleUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.hintSectionUpdated = value;
        this.htmlUpdated = value;
        this.urlsUpdated = value;
        this.roleUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid &&
            this.hintSectionValid &&
            this.htmlValid &&
            this.urlValid &&
            this.roleValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }

    get hintSectionValid(): boolean {
        return true;
    }

    get htmlValid(): boolean {
        return true;
    }

    get urlValid(): boolean {
        return true;
    }

    get roleValid(): boolean {
        return true;
    }
}