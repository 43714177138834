

















































import {Component, Vue} from "vue-property-decorator";
import FacilityInspector from "@/models/entities/facilityInspector/facilityInspector";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";

@Component
export default class FacilityInspectorComponent extends Vue {
  protected facilityInspectors: Array<FacilityInspector> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.FacilityInspector.listAsync().then((facilityInspectors: Array<FacilityInspector>) => {
      this.facilityInspectors = facilityInspectors;
    });
  }

  async saveAsync(facilityInspector: FacilityInspector) {
    if (facilityInspector.created)
      await this.createAsync(facilityInspector);
    else if (facilityInspector.updated)
      await this.editAsync(facilityInspector);
  }

  async deleteAsync(facilityInspector: FacilityInspector) {
    if (this.facilityInspectors &&
        this.facilityInspectors.length > 1) {
      if (facilityInspector.created)
        this.facilityInspectors = this.facilityInspectors.filter(x => x != facilityInspector);
      else
        await this.removeAsync(facilityInspector);
    }
  }

  async createAsync(facilityInspector: FacilityInspector) {
    try {
      const createdFacilityInspector = await Services.FacilityInspector.createAsync(facilityInspector);

      this.facilityInspectors.splice(this.facilityInspectors.indexOf(facilityInspector), 1, createdFacilityInspector);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(facilityInspector: FacilityInspector) {
    try {
      await Services.FacilityInspector.editAsync(facilityInspector);

      this.setUpdateTimeout(true);
      
      facilityInspector.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(facilityInspector: FacilityInspector) {
    try {
      await Services.FacilityInspector.removeAsync(facilityInspector);

      this.facilityInspectors = this.facilityInspectors.filter(x => x != facilityInspector);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const facilityInspector = new FacilityInspector(
        v4(),
        '',
        '',
        moment.utc()
    );

    facilityInspector.created = true;
    facilityInspector.updated = true;

    this.facilityInspectors.push(facilityInspector);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

