


















































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import {v4} from "uuid";
import {City} from "@/models/entities/city/city";
import {CityExtraCharge} from "@/models/entities/cityExtraCharge/cityExtraCharge";
import LayoutService from "@/services/LayoutService";

@Component
export default class CityExtraChargeComponent extends Vue{
  protected charges: Array<CityExtraCharge> = [];
  protected cities: Array<City> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.CityExtraCharge.listAsync().then((charges: Array<CityExtraCharge>) => {
      this.charges = charges;
    });
    await Services.City.listAsync().then((cities: Array<City>) => {
      this.cities = cities;
    });
  }

  async saveAsync(charge: CityExtraCharge) {
    if (charge.created)
      await this.createAsync(charge);
    else if (charge.updated)
      await this.editAsync(charge);
  }

  async deleteAsync(charge: CityExtraCharge) {
    if (this.charges) {
      if (charge.created)
        this.charges = this.charges.filter(x => x != charge);
      else
        await this.removeAsync(charge);
    }
  }

  async createAsync(charge: CityExtraCharge) {
    try {
      const createdCharge = await Services.CityExtraCharge.createAsync(charge);

      this.charges.splice(this.charges.indexOf(charge), 1, createdCharge);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(charge: CityExtraCharge) {
    try {
      await Services.CityExtraCharge.editAsync(charge);

      this.setUpdateTimeout(true);

      charge.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(charge: CityExtraCharge) {
    try {
      await Services.CityExtraCharge.removeAsync(charge);

      this.charges = this.charges.filter(x => x != charge);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const charge = new CityExtraCharge(
        v4(),
        this.cities[0],
        0
    );

    charge.created = true;
    charge.updated = true;

    this.charges.push(charge);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

