import {ShortManufacturerPrice, ShortManufacturerPriceDto} from "@/models/entities/price/shortManufacturerPrice";
import ValidatorService from "@/services/ValidatorService";
import {PaymentsCondition, PaymentsConditionDto} from "@/models/entities/defaultPaymentsCondition/PaymentsCondition";

export interface ShortPriceDto{
    id: number;
    name: string;
    isStandard: boolean;
    manufacturerPrices: ShortManufacturerPriceDto[];
    defaultPaymentsCondition: PaymentsConditionDto;
}

export class ShortPrice {
    public created = false;

    public nameUpdated = false;
    public isStandardUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string,
        public _isStandard: boolean,
        public manufacturerPrices: Array<ShortManufacturerPrice>,
        public defaultPaymentsCondition: PaymentsCondition
    ) {
    }

    public static fromDto(dto: ShortPriceDto): ShortPrice{
        return new ShortPrice(
            dto.id,
            dto.name,
            dto.isStandard,
            dto.manufacturerPrices.map(x => ShortManufacturerPrice.fromDto(x)),
            PaymentsCondition.fromObject(dto.defaultPaymentsCondition)
        )
    }


    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get isStandard(): boolean{
        return this._isStandard;
    }

    set isStandard(value: boolean){
        this._isStandard = value;
        this.isStandardUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.isStandardUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.isStandardUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }
}