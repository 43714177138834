import ValidatorService from "@/services/ValidatorService";

export interface HintSectionDto {
    id: number;
    name: string;
}

export class HintSection{
    public created = false;

    public nameUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string
    ) {
    }

    public static fromDto(dto: HintSectionDto): HintSection{
        return new HintSection(
            dto.id,
            dto.name
        )
    }

    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }
}