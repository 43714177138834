import {ServiceBase} from "@/services/ServiceBase";
import {ShortPrice, ShortPriceDto} from "@/models/entities/price/shortPrice";

export default class PriceService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<ShortPrice>> {
        return await this.postRequestWithResultAsync(
            `/api/Price/List`,
            {},
            (data: ShortPriceDto[]) => data.map(x => ShortPrice.fromDto(x)));
    }

    async getAsync(id: number): Promise<ShortPrice> {
        return await this.postRequestWithResultAsync(
            `/api/Price/Get`,
            {
                id: id
            },
            (data: ShortPriceDto) => ShortPrice.fromDto(data));
    }

    async createAsync(price: ShortPrice): Promise<ShortPrice> {
        return await this.postRequestWithResultAsync(
            `/api/Price/Create`,
            {
                name: price.name,
                isStandard: price.isStandard
            },
            (data: ShortPriceDto) => ShortPrice.fromDto(data));
    }

    async editAsync(price: ShortPrice): Promise<void> {
        return await this.postRequestAsync(
            `/api/Price/Edit`,
            {
                id: price.id,
                name: price.name
            });
    }

    async removeAsync(price: ShortPrice): Promise<void> {
        return await this.postRequestAsync(
            `/api/Price/Remove`,
            {
                id: price.id
            });
    }
}