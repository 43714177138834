import {ServiceBase} from "@/services/ServiceBase";
import Warehouse, {WarehouseDto} from "@/models/entities/warehouse/warehouse";

export default class WarehouseService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Warehouse>> {
        return await this.postRequestWithResultAsync(
            `/api/Warehouse/List`,
            {},
            (data: WarehouseDto[]) => data.map(x => Warehouse.fromDto(x)));
    }
}