import Manufacturer, {ManufacturerDto} from "@/models/entities/manufacturer/manufacturer";
import moment from "moment";

export interface ShortManufacturerPriceDto{
    id: number;
    updatedAtUtc: string | null;
    manufacturer: ManufacturerDto;
    existingCablePricesCount: number;
    expectedCablePricesCount: number;
}

export class ShortManufacturerPrice{
    public created = false;

    public manufacturerUpdated = false;

    constructor(
        public id: number | string,
        public readonly updatedAtUtc: moment.Moment | null,
        public _manufacturer: Manufacturer,
        public existingCablePricesCount: number,
        public expectedCablePricesCount: number
    ) {
    }

    public static fromDto(dto: ShortManufacturerPriceDto): ShortManufacturerPrice{
        return new ShortManufacturerPrice(
            dto.id,
            dto.updatedAtUtc ? moment.utc(dto.updatedAtUtc) : null,
            Manufacturer.fromDto(dto.manufacturer),
            dto.existingCablePricesCount,
            dto.expectedCablePricesCount
        )
    }


    get manufacturer(): Manufacturer{
        return this._manufacturer;
    }

    set manufacturer(value: Manufacturer){
        this._manufacturer = value;
        this.manufacturerUpdated = true;
    }

    get updated(): boolean {
        return this.manufacturerUpdated;
    }

    set updated(value: boolean) {
        this.manufacturerUpdated = value;
    }

    get valid(): boolean {
        return this.manufacturerValid;
    }

    get manufacturerValid(): boolean {
        return true;
    }
}