import {ServiceBase} from "@/services/ServiceBase";
import {HintSection, HintSectionDto} from "@/models/entities/hint/hintSection";

export default class HintSectionService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<HintSection>> {
        return await this.postRequestWithResultAsync(
            `/api/HintSection/List`,
            {},
            (data: HintSectionDto[]) => data.map(x => HintSection.fromDto(x)));
    }

    async createAsync(hintSection: HintSection): Promise<HintSection> {
        return await this.postRequestWithResultAsync(
            `/api/HintSection/Create`,
            {
                name: hintSection.name
            },
            (data: HintSectionDto) => HintSection.fromDto(data));
    }

    async editAsync(hintSection: HintSection): Promise<void> {
        return await this.postRequestAsync(
            `/api/HintSection/Edit`,
            {
                id: hintSection.id,
                name: hintSection.name
            });
    }

    async removeAsync(hintSection: HintSection): Promise<void> {
        return await this.postRequestAsync(
            `/api/HintSection/Remove`,
            {
                id: hintSection.id
            });
    }
}