import {ActionTree} from "vuex";
import {RootState} from "./types/RootState";
import {Profile} from "@/models/entities/profile/profile";
import {Brand} from "@/models/entities/product/cable/brand";
import Warehouse from "@/models/entities/warehouse/warehouse";

export const actions: ActionTree<RootState, RootState> = {
    async updateProfile({commit}, profile: Profile){
        commit('setProfile', profile);
    },
    async clearProfile({commit}){
        commit('clearProfile');
    },
    async updateBrands({commit}, brands: Array<Brand>){
        commit('updateBrands', brands);
    },
    async updateWarehouses({commit}, warehouses: Array<Warehouse>){
        commit('updateWarehouses', warehouses);
    }
};
