


















































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import LayoutService from "@/services/LayoutService";
import {Picture} from "@/models/entities/picture/picture";
import {PictureFileTypes} from "@/models/entities/picture/pictureFileTypes";
import {ShortPicture} from "@/models/entities/picture/shortPicture";

@Component
export default class PictureComponent extends Vue {
  protected pictures: Array<Picture> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  getSize(size: number): string{
    if (size < 1024)
      return `${size} Б`;
    if (size < 1024 * 1024)
      return `${Math.round(size / 1024)} Кб`;
    return `${Math.round(size / 1024 / 1024)} Мб`;
  }
  
  async loadAsync() {
    await Services.Picture.listAsync().then((pictures: Array<Picture>) => {
      this.pictures = pictures.filter(x => x.type === PictureFileTypes.HintImage);
    });
  }

  async createAsync(event: Event) {
    await this.uploadPictureAsync(event);
    await this.loadAsync();
  }
  
  async uploadPictureAsync(event: Event): Promise<ShortPicture> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        return await Services.File.createHintImageAsync(file);
      }
    }
    throw new Error();
  }

  async deleteAsync(picture: Picture) {
    if (this.pictures) {
        await this.removeAsync(picture);
    }
  }

  async removeAsync(picture: Picture) {
    try {
      await Services.Picture.removeAsync(picture);

      this.pictures = this.pictures.filter(x => x != picture);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

