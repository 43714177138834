import axios, {AxiosRequestConfig} from "axios";
import * as Responses from "@/models/responses";
import NotAuthorizedError from "@/models/errors/NotAuthtorizedError";
import ApplicationError from "@/models/errors/ApplicationError";
import {ShortPicture, ShortPictureDto} from "@/models/entities/picture/shortPicture";

export default class FileService{

    async createPictureAsync(file: File): Promise<ShortPicture> {
        return await this.postFileWithResponseAsync(
            `/api/Picture/Create`,
            file,
            (dto: ShortPictureDto) => ShortPicture.fromDto(dto));
    }

    async createHintImageAsync(file: File): Promise<ShortPicture> {
        return await this.postFileWithResponseAsync(
            `/api/Picture/CreateHintImage`,
            file,
            (dto: ShortPictureDto) => ShortPicture.fromDto(dto));
    }

    async importPriceAsync(file: File): Promise<void> {
        return await this.postFileAsync(
            `/api/ManufacturerPrice/Import`,
            file);
    }

    async importReelsAsync(file: File): Promise<void> {
        return await this.postFileAsync(
            `/api/Reel/Import`,
            file);
    }

    async importInventoryAsync(file: File): Promise<void> {
        return await this.postFileAsync(
            `/api/Inventory/Import`,
            file);
    }


    private async postFileWithResponseAsync<TRequest, TResponseData, TResponse>(
        url: string,
        file: File,
        mapFn: (data: TResponseData) => TResponse,
        config?: AxiosRequestConfig): Promise<TResponse> {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post<Responses.TypedApiResponseViewModel<TResponseData>>(url, formData, config)
            .catch((error) => {
                throw new Error(`HTTP Post error (${url}): ${error}.`);
            });

        const apiResponse = Responses.TypedApiResponse.fromObjectTyped(
            response.data,
            mapFn
        );

        if (!apiResponse.success){
            if (apiResponse.error?.code === 403)
                throw new NotAuthorizedError();
            throw new ApplicationError(apiResponse.error ? apiResponse.error.description : "Неизвестная ошибка.");
        }

        if (apiResponse.data) {
            return apiResponse.data;
        }

        throw new Error("Empty response data.")
    }

    private async postFileAsync<TRequest, TResponseData, TResponse>(
        url: string,
        file: File,
        config?: AxiosRequestConfig): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post<Responses.TypedApiResponseViewModel<TResponseData>>(url, formData, config)
            .catch((error) => {
                throw new Error(`HTTP Post error (${url}): ${error}.`);
            });

        const apiResponse = Responses.TypedApiResponse.fromObject(
            response.data
        );

        if (!apiResponse.success){
            if (apiResponse.error?.code === 403)
                throw new NotAuthorizedError();
            throw new ApplicationError(apiResponse.error ? apiResponse.error.description : "Неизвестная ошибка.");
        }
    }
}