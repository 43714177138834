


























































import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import {Category} from "@/models/entities/product/cable/category";
import {ShortPicture} from "../models/entities/picture/shortPicture";
import LayoutService from "@/services/LayoutService";

@Component
export default class CategoryComponent extends Vue{
  protected categories: Array<Category> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.Category.listAsync().then((categories: Array<Category>) => {
      this.categories = categories;
    });
  }

  async setIconAsync(event: Event, category: Category): Promise<void> {
    category.icon = await this.uploadPictureAsync(event);
  }

  async uploadPictureAsync(event: Event): Promise<ShortPicture> {
    const target: EventTarget | null = event.target;
    if (target instanceof HTMLInputElement) {
      const files: FileList | null = target.files;
      if (files) {
        const file: File = files[0];
        return await Services.File.createPictureAsync(file);
      }
    }
    throw new Error();
  }

  async saveAsync(category: Category) {
    if (category.created)
      await this.createAsync(category);
    else if (category.updated)
      await this.editAsync(category);
  }

  async deleteAsync(category: Category) {
    if (this.categories) {
      if (category.created)
        this.categories = this.categories.filter(x => x != category);
      else
        await this.removeAsync(category);
    }
  }

  async createAsync(category: Category) {
    try {
      const createdCategory = await Services.Category.createAsync(category);

      this.categories.splice(this.categories.indexOf(category), 1, createdCategory);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(category: Category) {
    try {
      await Services.Category.editAsync(category);

      this.setUpdateTimeout(true);

      category.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(category: Category) {
    try {
      await Services.Category.removeAsync(category);

      this.categories = this.categories.filter(x => x != category);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const category = new Category(
        v4(),
        'Новая категория',
        moment.utc(),
        null
    );

    category.created = true;
    category.updated = true;

    this.categories.push(category);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }

  getPictureUrl(id: number): string{
    return `/api/Picture/Get/${id}`;
  }
}

