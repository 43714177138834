import {ReservationTypes} from "@/models/entities/reservation/reservationTypes";

export interface ReservationDto{
    id: number;
    isActive: boolean;
    isBooked: boolean;
    type: ReservationTypes;
    dealerFirmName: string;
}

export default class Reservation{
    constructor(
        public id: number,
        public isActive: boolean,
        public isBooked: boolean,
        public type: ReservationTypes,
        public dealerFirmName: string
    ) {
    }

    public static fromDto(dto: ReservationDto): Reservation{
        return new Reservation(
            dto.id,
            dto.isActive,
            dto.isBooked,
            dto.type,
            dto.dealerFirmName
        )
    }
}