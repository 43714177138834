export default class ValidatorService {
    public static isEmailValid(email: string): boolean {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public static isLengthInRange(value: string, minLength: number, maxLength: number): boolean {
        return value.length >= minLength && value.length <= maxLength;
    }

    public static isNotEmpty(value: string): boolean {
        return value.length > 0;
    }

    public static isNumberInRange(value: number, min: number, max: number): boolean {
        return value >= min && value <= max;
    }

    public static isPositive(value: number): boolean {
        return value > 0;
    }
}