import moment from "moment";
import ValidatorService from "@/services/ValidatorService";

export interface CityDto {
    id: number;
    name: string;
    createdAtUtc: string;
}

export class City{
    public created = false;
    
    public nameUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string,
        public readonly createdAtUtc: moment.Moment
    ) {
    }
    
    public static fromObject(dto: CityDto): City{
        return new City(
            dto.id,
            dto.name,
            moment.utc(dto.createdAtUtc)
        )
    }

    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }
}