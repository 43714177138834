export interface InventoryReportReelDto {
    id: number;
    cable: string;
    number: string;
    year: string;
    length: number;
}

export class InventoryReportReel {
    public constructor(
        public readonly id: number | string,
        public readonly cable: string,
        public readonly number: string,
        public readonly year: string,
        public readonly length: number
    ) {
    }

    public static fromDto(dto: InventoryReportReelDto): InventoryReportReel{
        return new InventoryReportReel(
            dto.id,
            dto.cable,
            dto.number,
            dto.year,
            dto.length);
    }
}