import moment from 'moment';
import {PaymentsConditionItem} from "@/models/entities/defaultPaymentsCondition/PaymentsConditionItem";
import {PaymentsCondition, PaymentsConditionDto} from "@/models/entities/defaultPaymentsCondition/PaymentsCondition";

export class DefaultPaymentsCondition extends PaymentsCondition{
    constructor(
        updatedAtUtc: moment.Moment | null,
        items: Array<PaymentsConditionItem>)
    {
        super(updatedAtUtc, items)
    }

    public static fromObject(data: PaymentsConditionDto): DefaultPaymentsCondition {
        return new DefaultPaymentsCondition(
            data.updatedAtUtc ? moment.utc(data.updatedAtUtc) : null,
            data.items.map(x => PaymentsConditionItem.fromObject(x)));
    }
}