import {ServiceBase} from "@/services/ServiceBase";
import {Employee} from "@/models/entities/common/employee";

export interface EmployeeConstructor<TEmployee, TEmployeeDto> {
    new(...args: any[]): TEmployee;
    entityName: string;
    fromObject(data: TEmployeeDto): TEmployee;
}

export default class EmployeeService<TEmployee, TEmployeeDto> extends ServiceBase {
    constructor(private readonly employeeType: EmployeeConstructor<TEmployee, TEmployeeDto>) {
        super();
    }

    async listAsync(): Promise<Array<TEmployee>> {
        return await this.postRequestWithResultAsync(
            `/api/${this.employeeType.entityName}/List`,
            {
            },
            (data: TEmployeeDto[]) => data.map(x => this.employeeType.fromObject(x)));
    }

    async createAsync(employee: Employee): Promise<TEmployee> {
        return await this.postRequestWithResultAsync(
            `/api/${this.employeeType.entityName}/Create`,
            {
                name: employee.name,
                email: employee.email
            },
            (data: TEmployeeDto) => this.employeeType.fromObject(data));
    }

    async editAsync(employee: Employee): Promise<void> {
        return await this.postRequestAsync(
            `/api/${this.employeeType.entityName}/Edit`,
            {
                id: employee.id,
                name: employee.name,
                email: employee.email
            });
    }

    async removeAsync(employee: Employee): Promise<void> {
        return await this.postRequestAsync(
            `/api/${this.employeeType.entityName}/Remove`,
            {
                id: employee.id
            });
    }
}
