






























































































import Vue from "vue";
import Component from "vue-class-component";
import ProfileComponent from "@/components/ProfileComponent.vue";
@Component({
  components: {ProfileComponent}
})
export default class App extends Vue {
  protected page = 0;
}
