





















































import {Component, Vue} from "vue-property-decorator";
import FacilityManager from "@/models/entities/facilityManager/facilityManager";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";

@Component
export default class FacilityManagerComponent extends Vue {
  protected facilityManagers: Array<FacilityManager> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.FacilityManager.listAsync().then((facilityManagers: Array<FacilityManager>) => {
      this.facilityManagers = facilityManagers;
    });
  }

  async saveAsync(facilityManager: FacilityManager) {
    if (facilityManager.created)
      await this.createAsync(facilityManager);
    else if (facilityManager.updated)
      await this.editAsync(facilityManager);
  }

  async deleteAsync(facilityManager: FacilityManager) {
    if (this.facilityManagers) {
      if (facilityManager.created)
        this.facilityManagers = this.facilityManagers.filter(x => x != facilityManager);
      else
        await this.removeAsync(facilityManager);
    }
  }

  async createAsync(facilityManager: FacilityManager) {
    try {
      const createdFacilityManager = await Services.FacilityManager.createAsync(facilityManager);

      this.facilityManagers.splice(this.facilityManagers.indexOf(facilityManager), 1, createdFacilityManager);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(facilityManager: FacilityManager) {
    try {
      await Services.FacilityManager.editAsync(facilityManager);

      this.setUpdateTimeout(true);
      
      facilityManager.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(facilityManager: FacilityManager) {
    try {
      await Services.FacilityManager.removeAsync(facilityManager);

      this.facilityManagers = this.facilityManagers.filter(x => x != facilityManager);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const facilityManager = new FacilityManager(
        v4(),
        '',
        '',
        moment.utc(),
        0);

    facilityManager.created = true;
    facilityManager.updated = true;

    this.facilityManagers.push(facilityManager);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

