import Reservation, {ReservationDto} from "@/models/entities/reservation/reservation";
import ValidatorService from "@/services/ValidatorService";

export interface ReelDto{
    id: number;
    cableName: string;
    number: string;
    year: string | null;
    size: string;
    length: number;
    reservation: ReservationDto | null;
}

export default class Reel{

    public lengthToRemoveUpdated = false;

    constructor(
        public id: number,
        public cableName: string,
        public number: string,
        public year: string | null,
        public size: string,
        public length: number,
        public reservation: Reservation | null,
        public _lengthToRemove: number
    ) {
    }

    public static fromDto(dto: ReelDto): Reel{
        return new Reel(
            dto.id,
            dto.cableName,
            dto.number,
            dto.year,
            dto.size,
            dto.length,
            dto.reservation ? Reservation.fromDto(dto.reservation) : null,
            dto.length
        )
    }

    get name(): string {
        return this.number;
    }

    get lengthToRemove(): number {
        return this._lengthToRemove;
    }

    set lengthToRemove(value: number) {
        this._lengthToRemove = value;
        this.lengthToRemoveUpdated = true;
    }

    get lengthToRemoveValid(): boolean {
        return ValidatorService.isNumberInRange(this.lengthToRemove, 0.001, this.length);
    }
}