import moment from "moment";
import {ShortPicture, ShortPictureDto} from "../../picture/shortPicture";
import {Category, CategoryDto} from "@/models/entities/product/cable/category";
import ValidatorService from "@/services/ValidatorService";

export interface BrandDto {
    id: number | string;
    name: string;
    latinName: string;
    createdAtUtc: string;
    description: string;
    minOrderLength: number;
    minLengthOnDrum: number;
    descriptionLink: string;
    category: CategoryDto;
    pageImage: ShortPictureDto;
    logo: ShortPictureDto;
}

export class Brand {
    public picturesLoaded = false;

    public created = false;

    public nameUpdated = false;
    public latinNameUpdated = false;
    public descriptionUpdated = false;
    public descriptionLinkUpdated = false;
    public minOrderLengthUpdated = false;
    public minLengthOnDrumUpdated = false;
    public categoryUpdated = false;
    public logoUpdated = false;
    public pageImageUpdated = false;


    constructor(
        public readonly id: number | string,
        public _name: string,
        public _latinName: string,
        public _description: string,
        public _descriptionLink: string,
        public _minOrderLength: number,
        public _minLengthOnDrum: number,
        public readonly createdAtUtc: moment.Moment,
        public _category: Category,
        public _pageImage: ShortPicture | null,
        public _logo: ShortPicture | null
    ) {
    }

    public static fromDto(dto: BrandDto): Brand {
        return new Brand(
            dto.id,
            dto.name,
            dto.latinName,
            dto.description,
            dto.descriptionLink,
            dto.minOrderLength,
            dto.minLengthOnDrum,
            moment.utc(dto.createdAtUtc),
            Category.fromDto(dto.category),
            ShortPicture.fromDto(dto.pageImage),
            ShortPicture.fromDto(dto.logo)
        )
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
        this.nameUpdated = true;
    }

    get latinName(): string {
        return this._latinName;
    }

    set latinName(value: string) {
        this._latinName = value;
        this.latinNameUpdated = true;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
        this.descriptionUpdated = true;
    }

    get descriptionLink(): string {
        return this._descriptionLink;
    }

    set descriptionLink(value: string) {
        this._descriptionLink = value;
        this.descriptionLinkUpdated = true;
    }

    get minOrderLength(): number {
        return this._minOrderLength;
    }

    set minOrderLength(value: number) {
        this._minOrderLength = value;
        this.minOrderLengthUpdated = true;
    }

    get minLengthOnDrum(): number {
        return this._minLengthOnDrum;
    }

    set minLengthOnDrum(value: number) {
        this._minLengthOnDrum = value;
        this.minLengthOnDrumUpdated = true;
    }

    get category(): Category {
        return this._category;
    }

    set category(value: Category) {
        this._category = value;
        this.categoryUpdated = true;
    }

    get pageImage(): ShortPicture | null {
        return this._pageImage;
    }

    set pageImage(value: ShortPicture | null) {
        this._pageImage = value;
        this.pageImageUpdated = true;
    }

    get logo(): ShortPicture | null {
        return this._logo;
    }

    set logo(value: ShortPicture | null) {
        this._logo = value;
        this.logoUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.latinNameUpdated ||
            this.descriptionUpdated ||
            this.descriptionLinkUpdated ||
            this.minOrderLengthUpdated ||
            this.minLengthOnDrumUpdated ||
            this.categoryUpdated ||
            this.logoUpdated ||
            this.pageImageUpdated
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.latinNameUpdated = value;
        this.descriptionUpdated = value;
        this.descriptionLinkUpdated = value;
        this.minOrderLengthUpdated = value;
        this.minLengthOnDrumUpdated = value;
        this.categoryUpdated = value;
        this.logoUpdated = value;
        this.pageImageUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid &&
            this.latinNameValid &&
            this.descriptionValid &&
            this.descriptionLinkValid &&
            this.minOrderLengthValid &&
            this.minLengthOnDrumValid &&
            this.categoryValid &&
            this.pageImageValid &&
            this.logoValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 16);
    }

    get latinNameValid(): boolean {
        return ValidatorService.isLengthInRange(this.latinName, 1, 16);
    }

    get descriptionValid(): boolean {
        return ValidatorService.isNotEmpty(this.description);
    }

    get descriptionLinkValid(): boolean {
        return ValidatorService.isNotEmpty(this.descriptionLink);
    }

    get minOrderLengthValid(): boolean {
        return ValidatorService.isPositive(this.minOrderLength);
    }

    get minLengthOnDrumValid(): boolean {
        return ValidatorService.isPositive(this.minLengthOnDrum);
    }

    get categoryValid(): boolean {
        return this.category !== null;
    }

    get pageImageValid(): boolean {
        return this.pageImage !== null;
    }

    get logoValid(): boolean {
        return this.logo !== null;
    }
}