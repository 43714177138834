import PaymentsConditionService from "./PaymentsConditionService";
import OurFirmService from "@/services/OurFirmService";
import EmployeeService from "@/services/EmployeeService";
import Logist from "@/models/entities/logist/logist";
import FacilityManager from "@/models/entities/facilityManager/facilityManager";
import FacilityInspector from "@/models/entities/facilityInspector/facilityInspector";
import CityService from "@/services/CityService";
import CityExtraChargeService from "@/services/CityExtraChargeService";
import CategoryService from "@/services/CategoryService";
import BrandService from "@/services/BrandService";
import FileService from "@/services/FileService";
import AccountService from "@/services/AccountService";
import ProfileService from "@/services/ProfileService";
import RemainsLengthsService from "@/services/RemainsLengthsService";
import WarehouseService from "@/services/WarehouseService";
import ReelHoldingTimeSpanService from "@/services/ReelHoldingTimeSpanService";
import InventoryReportService from "@/services/InventoryReportService";
import PriceService from "@/services/PriceService";
import ManufacturerPriceService from "@/services/ManufacturerPriceService";
import ManufacturerService from "@/services/ManufacturerService";
import HintSectionService from "@/services/HintSectionService";
import HintService from "@/services/HintService";
import PictureService from "@/services/PictureService";
import HintLinkService from "@/services/HintLinkService";
import ShellService from "@/services/ShellService";
import ExclusiveCategoryService from "@/services/ExclusiveCategoryService";
import ReelService from "@/services/ReelService";

const Services = {
    PaymentsCondition: new PaymentsConditionService(),
    OurFirm: new OurFirmService(),
    Logist: new EmployeeService(Logist),
    FacilityInspector: new EmployeeService(FacilityInspector),
    FacilityManager: new EmployeeService(FacilityManager),
    City: new CityService(),
    CityExtraCharge: new CityExtraChargeService(),
    Category: new CategoryService(),
    Brand: new BrandService(),
    File: new FileService(),
    Account: new AccountService(),
    Profile: new ProfileService(),
    RemainsLengths: new RemainsLengthsService(),
    Warehouse: new WarehouseService(),
    ReelHoldingTimeSpan: new ReelHoldingTimeSpanService(),
    InventoryReport: new InventoryReportService(),
    Price: new PriceService(),
    ManufacturerPrice: new ManufacturerPriceService(),
    Manufacturer: new ManufacturerService(),
    HintSection: new HintSectionService(),
    Hint: new HintService(),
    Picture: new PictureService(),
    HintLink: new HintLinkService(),
    Shell: new ShellService(),
    ExclusiveCategory: new ExclusiveCategoryService(),
    Reel: new ReelService()
};

export default Services;