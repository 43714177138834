import {Brand} from "@/models/entities/product/cable/brand";
import Services from "@/services";
import store from "@/store";

export default class BrandRepository{

    async getAsync(id: string | number): Promise<Brand> {
        if (store.getters.brands === null){
            await this.updateAsync();
        }

        return store.getters.brands.find((x: Brand) => x.id == id);
    }
    
    async listAsync(): Promise<Array<Brand>> {
        if (store.getters.brands === null){
            await this.updateAsync();
        }

        return store.getters.brands;
    }

    async createAsync(brand: Brand): Promise<Brand> {
        const createdBrand = await Services.Brand.createAsync(brand);
        await this.updateAsync();

        return await this.getAsync(createdBrand.id);
    }

    async editAsync(brand: Brand): Promise<void> {
        await Services.Brand.editAsync(brand);
        await this.updateAsync();
    }

    async removeAsync(brand: Brand): Promise<void> {
        await Services.Brand.removeAsync(brand);
        await this.updateAsync();
    }


    async updateAsync(): Promise<void>{
        const brands = await Services.Brand.listAsync();
        await store.dispatch('updateBrands', brands);
    }
}