












































import {Component, Vue} from "vue-property-decorator";
import {City} from "@/models/entities/city/city";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";
import moment from "moment";
import {v4} from "uuid";
import LayoutService from "@/services/LayoutService";

@Component
export default class CityComponent extends Vue{
  protected cities: Array<City> = [];

  protected updatedSuccessful: boolean | null = null;
  protected errorMessages: Array<string> = [];

  async mounted() {
    await this.loadAsync();
  }

  async loadAsync() {
    await Services.City.listAsync().then((cities: Array<City>) => {
      this.cities = cities;
    });
  }

  async saveAsync(city: City) {
    if (city.created)
      await this.createAsync(city);
    else if (city.updated)
      await this.editAsync(city);
  }

  async deleteAsync(city: City) {
    if (this.cities) {
      if (city.created)
        this.cities = this.cities.filter(x => x != city);
      else
        await this.removeAsync(city);
    }
  }

  async createAsync(city: City) {
    try {
      const createdCity = await Services.City.createAsync(city);

      this.cities.splice(this.cities.indexOf(city), 1, createdCity);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async editAsync(city: City) {
    try {
      await Services.City.editAsync(city);

      this.setUpdateTimeout(true);

      city.updated = false;
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  async removeAsync(city: City) {
    try {
      await Services.City.removeAsync(city);

      this.cities = this.cities.filter(x => x != city);

      this.setUpdateTimeout(true);
    } catch (error) {
      this.errorMessages.splice(0, this.errorMessages.length);
      if (error instanceof ApplicationError)
        this.errorMessages.push(error.message)
      else
        this.errorMessages.push('Неизвестная ошибка');
      this.setUpdateTimeout(false);
    }
  }

  addEmpty() {
    const city = new City(
        v4(),
        '',
        moment.utc()
    );

    city.created = true;
    city.updated = true;

    this.cities.push(city);
  }

  setUpdateTimeout(success: boolean) {
    this.updatedSuccessful = success;
    setTimeout(() => this.updatedSuccessful = null, 4000);
  }

  validationClass(changed: boolean, valid: boolean): string {
    return LayoutService.validationClass(changed, valid);
  }
}

