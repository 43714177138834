export interface WarehouseDto{
    id: number;
    name: string;
}

export default class Warehouse{
    constructor(
        public id: number,
        public name: string
    ) {
    }

    public static fromDto(dto: WarehouseDto): Warehouse{
        return new Warehouse(
            dto.id,
            dto.name
        )
    }
}