import axios, {AxiosRequestConfig} from 'axios';
import * as Responses from '@/models/responses';
import NotAuthorizedError from "@/models/errors/NotAuthtorizedError";
import ApplicationError from "@/models/errors/ApplicationError";
import store from "@/store";
import router from "@/router";

export abstract class ServiceBase {
    protected readonly defaultRequestConfig: AxiosRequestConfig = {
        headers: {
            "X-Requested-With": "XMLHttpRequest"
        }
    };

    protected async postRequestAsync<TRequest>(
        url: string,
        request: TRequest,
        config?: AxiosRequestConfig): Promise<void> {

        if (!config)
            config = this.defaultRequestConfig;

        const response = await axios.post<Responses.ApiResponseViewModel>(url, request, config)
            .catch((error) => {
                if (error.response.status === 401){
                    store.dispatch('clearProfile');
                    router.push('/login');
                    throw new NotAuthorizedError();
                }
                throw new Error(`HTTP Post error (${url}): ${error}.`);
            });

        const apiResponse = Responses.ApiResponse.fromObject(response.data);

        if (!apiResponse.success){
            throw new ApplicationError(apiResponse.error ? apiResponse.error.description : "Неизвестная ошибка.");
        }

    }

    protected async postRequestWithResultAsync<TRequest, TResponseData, TResponse>(
        url: string,
        request: TRequest,
        mapFn: (data: TResponseData) => TResponse,
        config?: AxiosRequestConfig): Promise<TResponse> {

        if (!config)
            config = this.defaultRequestConfig;

        const response = await axios.post<Responses.TypedApiResponseViewModel<TResponseData>>(url, request, config)
            .catch((error) => {
                if (error.response.status === 401){
                    store.dispatch('clearProfile');
                    router.push('/login');
                    throw new NotAuthorizedError();
                }
                throw new Error(`HTTP Post error (${url}): ${error}.`);
            });

        const apiResponse = Responses.TypedApiResponse.fromObjectTyped(
            response.data,
            mapFn
        );

        if (!apiResponse.success){
            throw new ApplicationError(apiResponse.error ? apiResponse.error.description : "Неизвестная ошибка.");
        }

        if (apiResponse.data) {
            return apiResponse.data;
        }

        throw new Error("Empty response data.")
    }
}