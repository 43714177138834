import {ServiceBase} from "@/services/ServiceBase";
import {Category, CategoryDto} from "@/models/entities/product/cable/category";

export default class CategoryService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Category>> {
        return await this.postRequestWithResultAsync(
            `/api/Category/List`,
            {},
            (dtos: CategoryDto[]) => dtos.map(x => Category.fromDto(x)));
    }

    async createAsync(category: Category): Promise<Category> {
        return await this.postRequestWithResultAsync(
            `/api/Category/Create`,
            {
                name: category.name,
                iconId: category.icon?.id
            },
            (dto: CategoryDto) => Category.fromDto(dto));
    }

    async editAsync(category: Category): Promise<void> {
        return await this.postRequestAsync(
            `/api/Category/Edit`,
            {
                id: category.id,
                name: category.name,
                iconId: category.icon?.id
            });
    }

    async removeAsync(category: Category): Promise<void> {
        return await this.postRequestAsync(
            `/api/Category/Remove`,
            {
                id: category.id
            });
    }
}