import {ServiceBase} from "@/services/ServiceBase";
import Reel, {ReelDto} from "@/models/entities/product/reel/reel";

export default class ReelService extends ServiceBase {
    constructor() {
        super();
    }

    async listAsync(): Promise<Array<Reel>> {
        return await this.postRequestWithResultAsync(
            `/api/Reel/List`,
            { },
            (data: ReelDto[]) => data.map(x => Reel.fromDto(x)));
    }

    async removeAsync(reel: Reel): Promise<void> {
        return await this.postRequestAsync(
            `/api/Reel/Remove`,
            {
                id: reel.id,
                length: reel.lengthToRemove
            });
    }
}