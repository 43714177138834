import {ServiceBase} from "@/services/ServiceBase";
import {Shell, ShellDto} from "@/models/entities/product/cable/shell";

export default class ShellService extends ServiceBase {
    constructor() {
        super();
    }

    async syncAsync(): Promise<void> {
        return await this.postRequestAsync(`/api/Shell/Sync`,
         {});
    }

    async listAsync(): Promise<Array<Shell>> {
        return await this.postRequestWithResultAsync(
            `/api/Shell/List`,
            {},
            (data: ShellDto[]) => data.map(x => Shell.fromDto(x)));
    }

    async createAsync(shell: Shell): Promise<Shell> {
        return await this.postRequestWithResultAsync(
            `/api/Shell/Create`,
            {
                name: shell.name,
                latinName: shell.latinName
            },
            (data: ShellDto) => Shell.fromDto(data));
    }

    async editAsync(shell: Shell): Promise<void> {
        return await this.postRequestAsync(
            `/api/Shell/Edit`,
            {
                id: shell.id,
                name: shell.name,
                latinName: shell.latinName
            });
    }

    async removeAsync(shell: Shell): Promise<void> {
        return await this.postRequestAsync(
            `/api/Shell/Remove`,
            {
                id: shell.id
            });
    }
}