import {ServiceBase} from "@/services/ServiceBase";
import {ExclusiveCategory, ExclusiveCategoryDto} from "@/models/entities/product/cable/exclusiveCategory";

export default class ExclusiveCategoryService extends ServiceBase {
    constructor() {
        super();
    }

    async getAsync(id: number): Promise<ExclusiveCategory> {
        return await this.postRequestWithResultAsync(
            `/api/ExclusiveCategory/Get`,
            {
                id: id
            },
            (data: ExclusiveCategoryDto) => ExclusiveCategory.fromDto(data));
    }
    
    async listAsync(): Promise<Array<ExclusiveCategory>> {
        return await this.postRequestWithResultAsync(
            `/api/ExclusiveCategory/List`,
            {},
            (data: ExclusiveCategoryDto[]) => data.map(x => ExclusiveCategory.fromDto(x)));
    }

    async createAsync(exclusiveCategory: ExclusiveCategory): Promise<ExclusiveCategory> {
        return await this.postRequestWithResultAsync(
            `/api/ExclusiveCategory/Create`,
            {
                description: exclusiveCategory.description,
                descriptionLink: exclusiveCategory.descriptionLink,
                categoryId: exclusiveCategory.category?.id,
                brandId: exclusiveCategory.brand?.id,
                logoId: exclusiveCategory.logo?.id,
                pageImageId: exclusiveCategory.pageImage?.id,
                shellIds: exclusiveCategory.shells.map(x => x.id)
            },
            (data: ExclusiveCategoryDto) => ExclusiveCategory.fromDto(data));
    }

    async editAsync(exclusiveCategory: ExclusiveCategory): Promise<void> {
        return await this.postRequestAsync(
            `/api/ExclusiveCategory/Edit`,
            {
                id: exclusiveCategory.id,
                description: exclusiveCategory.description,
                descriptionLink: exclusiveCategory.descriptionLink,
                categoryId: exclusiveCategory.category?.id,
                brandId: exclusiveCategory.brand?.id,
                logoId: exclusiveCategory.logo?.id,
                pageImageId: exclusiveCategory.pageImage?.id,
                shellIds: exclusiveCategory.shells.map(x => x.id)
            });
    }

    async removeAsync(exclusiveCategory: ExclusiveCategory): Promise<void> {
        return await this.postRequestAsync(
            `/api/ExclusiveCategory/Remove`,
            {
                id: exclusiveCategory.id
            });
    }
}