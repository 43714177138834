























import {Component, Vue} from "vue-property-decorator";
import Services from "@/services";
import ApplicationError from "@/models/errors/ApplicationError";

@Component
export default class LoginComponent extends Vue {
  protected login = '';
  protected password = '';

  protected success: boolean | null = null;
  protected errorMessage: string | null = null;

  async loginAsync() {
    try {
      await Services.Account.loginAsync(this.login, this.password);

      this.success = true;

      await this.$router.push('ourFirm');
    } catch (error) {
      if (error instanceof ApplicationError)
        this.errorMessage = error.message;
      else
        this.errorMessage = 'Неизвестная ошибка';
      this.success = false;
      setTimeout(() => this.success = null, 4000);
    }
  }
}
