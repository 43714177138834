import moment from "moment";
import ValidatorService from "@/services/ValidatorService";

export interface ShellDto{
    id: number | string;
    name: string;
    latinName: string;
    createdAtUtc: string;
}

export class Shell{
    public created = false;

    public nameUpdated = false;
    public latinNameUpdated = false;

    constructor(
        public readonly id: number | string,
        public _name: string,
        public _latinName: string,
        public readonly createdAtUtc: moment.Moment
    ) {
    }

    public static fromDto(dto: ShellDto): Shell{
        return new Shell(
            dto.id,
            dto.name,
            dto.latinName,
            moment.utc(dto.createdAtUtc)
        )
    }


    get name(): string{
        return this._name;
    }

    set name(value: string){
        this._name = value;
        this.nameUpdated = true;
    }

    get latinName(): string{
        return this._latinName;
    }

    set latinName(value: string){
        this._latinName = value;
        this.latinNameUpdated = true;
    }

    get updated(): boolean {
        return this.nameUpdated ||
            this.latinNameUpdated;
    }

    set updated(value: boolean) {
        this.nameUpdated = value;
        this.latinNameUpdated = value;
    }

    get valid(): boolean {
        return this.nameValid &&
            this.latinNameValid;
    }

    get nameValid(): boolean {
        return ValidatorService.isLengthInRange(this.name, 1, 32);
    }

    get latinNameValid(): boolean {
        return ValidatorService.isLengthInRange(this.latinName, 1, 32);
    }
}